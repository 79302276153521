import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from 'src/swagger-typescript';

@Component({
  selector: 'app-assessment-selection',
  templateUrl: './assessment-selection.component.html',
  styleUrls: ['./assessment-selection.component.css']
})
export class AssessmentSelectionComponent implements OnInit {

  /** stores the name of the assessment project */
  private searchTerm : string = "";

  /** indicate wheter to show the to show a button after selecting a project */
  private showNextButton: boolean = false;

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  @Output() backEventEmitter = new EventEmitter<string>();
  @Output() startEventEmitter = new EventEmitter<string>();
  @Output() resultsEventEmitter = new EventEmitter<string>();
  @Output() reviewEventEmitter = new EventEmitter<string>();

  /** stores all assessment projects in an array */
  @Input("projectList") projectList : Project[] = [];

  /** button that was pressed to show this component */
  @Input("sourceButton") sourceButton : string;

  constructor() { }

  ngOnInit() {
  }

  /***************************************************************************/
   
  /** set searchTerm to project which was selected in the dropdown menu */
  changeSelection(str: string) : void {
    this.searchTerm = str;
    this.showNextButton = true;
  }

  /***************************************************************************/
   
  /** set searchTerm to project which was selected in the dropdown menu */
  continue() : void {
    if (this.sourceButton == "startReview")
    {
      this.reviewEventEmitter.emit(this.searchTerm);
    }
    else if (this.sourceButton == "startResults")
    {
      this.resultsEventEmitter.emit(this.searchTerm);
    }
    else if (this.sourceButton == "startSurvey")
    {
      this.startEventEmitter.emit(this.searchTerm);
    }
    else {
      this.popupText = "No Assessment chosen";
      this.showPopup = true;
    }
  }

  /***************************************************************************/
   
  /** set searchTerm to project which was selected in the dropdown menu */
  back() : void {
    this.backEventEmitter.emit("back");
  }

  /***************************************************************************/

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
  }

}
