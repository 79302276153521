import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTranslation',
  pure: false
})
export class FilterTranslationPipe implements PipeTransform {

  /**
     * function to filter an input array and return entries which include the search term
     * 
     * @param {any} inputArray input array which should be filtered asm.questions[currentQuestion].text
     * @param {any} language search term which should be filtered for
     * @return {any} filtered array
     */
    transform(inputArray: any, language: any)
    {
        if (language === undefined) return inputArray[0].content;

        // filter input array and return the content which matches the language
        var helperObj = inputArray.filter(data => data.lang == language)[0];

        if (helperObj == undefined || helperObj.content == undefined || helperObj.content == "")
        {
          return "undefined";
        }
        return helperObj.content;
    }

}
