// translations
export const LANG_EN_TRANS = {
    
    // applicationwide
    'Assessment':                'Assessment',
    'Topics':                    'Topics',
    'Topic':                     'Topic',
    'Result':                    'Result',
    'Imprint':                   'Imprint',
    'Privacy':                   'Privacy',
    'Manage assessments':        'Manage Assessments',
    'Settings':                  'Settings',

    //introduction
    'Jump to category':          'Jump to category',
    'First question':            'First question',
    'Introduction':              'Introduction',

    //result
    'save':                      'save',
    'current state':             'Current state',
    'target state':              'Target state',
    'relevance':                 'Relevance', 
    'Request a Review':          'Request a Review',
    'recommendation':            'Recommendation',

    //survey
    'Question':                  'Question',
    'of':                        'of',
    'Choose assessment':         'Choose assessment',
    'Calculate results':         'Calculate results',
    'Next question':             'Next question',
    'Last question':             'Last question',

    //assessment selection
    'Start Assessment for Project':     'Start Assessment for Project',
    'Look at results':                  'Look at results',
    'Start assessment':                 'Start assessment',
    'Start review':                     'Start review',
    'Choose project name':              'Choose project name',
    'Edit assessment':                  'Edit assessment',
    'Deploy assessment':                'Deploy assessment',
    'New assessment':                   'New assessment',

    //login
    'Username':         'Username',        
    'Password':         'Password',        
    'Repeat password':  'Repeat password', 
    'Email':            'Email',           
    'Login':            'Login',           
    'Logout':           'Logout',           
    'Register':         'Register',        
    'To':               'To',

    // create / edit assessments
    'Introduction Text':            'Introduction Text', 
    'Assessment Title':             'Assessment Title', 
    'Load Assessment':              'Load Assessment', 
    'Language':                     'Language', 
    'Category Name':                'Category Name',
    'Sub Category Name':            'Sub Category Name', 
    'Question Text':                'Question Text',  
    'Answer Text':                  'Answer Text',  
    'Answer':                       'Answer',  
    'Relevancy':                    'Relevancy',  
    'Standard score':               'Standard score',  
    'Add answer':                   'Add answer',  
    'Add relevancy answer':         'Add relevancy answer',  
    'Add relation':                 'Add relation',  
    'Add recommendation':           'Add recommendation',  
    'Add question':                 'Add question',  
    'Add sub category':             'Add sub category',  
    'Add category':                 'Add category',  
    'Save':                         'Save',  
    'Assessment is a Template':     'Assessment is a Template for further Assessments',

    // discribute assessment
    'Distribute to Mail Address':               'Distribute to Mail Address',  
    'View rights':                              'View rights',  
    'Edit rights':                              'Edit rights',  
    'User Type':                                'User Type',  
    'Basic user rights':                        'Basic user rights',  
    'Creator rights':                           'Creator rights',  
    'Send':                                     'Send',  
    'Seperate multiple adresses with a SPACE':  'Seperate multiple adresses with a SPACE',

    // settings
    'Old password':                 'Old password',  
    'New password':                 'New password',  
    'Repeat new password':          'Repeat new password',  
    'Change password':              'Change password',  
    
};