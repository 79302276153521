import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectsService } from '../../swagger-typescript/api/projects.service';
import { Project } from '../../swagger-typescript/model/project';
import { StorageService } from '../storage.service';
import { Subscription } from 'rxjs';
import sampleData from '../../../template_englisch_final.json'
import { AssessmentService } from 'src/swagger-typescript/api/assessment.service';
import { UserService } from 'src/swagger-typescript';
import { TranslationService } from 'src/translation/translation.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public exData : any = sampleData;

  /** currently not in use - will be needed to show filtered Project list */
  private listVisible : boolean = false;

  /** page is invisibale if server request for page materials is unsuccessful */
  public loadingSuccess: boolean = false;

  /** stores which navigation button was pressed */
  private navigationTerm : string = "";

  /** stores the name of the assessment project */
  private searchTerm : string = "";

  /** indicate theat data is beeing retrieved from the DB */
  private isLoading : boolean = false;

  /** stores all assessment projects in an array */
  private projectList : Project[] = [];

  /** used to fill progressbar continiously */
  private progressCounter: number = 0; 

  /** used to set cyclic interval for progressbar */
  public progressInterval;

  /** used to indicate if a new user is loggin in for the first time */
  public changeNameIndicator: boolean = false;

  /** used to indicate if a new user has accepted the terms of service */
  public termsIndicator: boolean = false;

  /** Subscription for Assessments from the DB */
  private asmSubscription: Subscription;

  /** Subscription for Errors from the DB-conenction */
  private errorSubscription: Subscription;

  /** Used to remind people they have to chose a project befor pressing a button */
  private chooseReminder: boolean;

  /** indicate if page already loaded */
  private initialPageLoad: boolean = true;

  private assessmentSelection: boolean = false;

  private sourceButton: string = "";

  private oldName: string = "";
  private newName: string = "";

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  /** constructor */
  constructor(private router: Router,
              private projectsService: ProjectsService,
              private storageService: StorageService, 
              private assessmentService: AssessmentService,
              private userService: UserService,
              private translator: TranslationService
              ) { }

  /***************************************************************************/
   
  /**
   * Lifecycle hook - triggered on page initialization
   * Display a list of all assessment projects
   */
  ngOnInit() 
  {
    this.storageService.resetAssessment(); 

    // load assessment data
    this.asmSubscription = this.storageService.asmItem$
    .subscribe((data: any) => 
    {
      // no assessment found for that project name
      if (data == false || data == null || data == undefined) 
      {
        return;
      }

      this.isLoading = false;
      this.router.navigate([this.navigationTerm, this.searchTerm]);
    });

    // navigate to login page, if server responds with status 401 to assessment request
    this.errorSubscription = this.storageService.errorItem$
    .subscribe((error: any) => 
    {

      if (this.initialPageLoad == true)
      {
        this.getAllProjects();
        this.initialPageLoad = false;
        return;
      }

      if (error == null)
      {
        return;
      }
      else if(error.status == 401)
      {
        this.isLoading = false;
        this.router.navigate(['/login', 'login']);
      }
      this.isLoading = false;
    });

    this.oldName = this.storageService.currentUser;
  }

  /*showList() {
    this.listVisible = true;  
  }

  hideList() {

      var interval = setInterval(() => {
        this.listVisible = false;
        clearInterval(interval);
      },100) 
  }

  selectItem(str: String) {
    this.searchTerm = str;
  }*/

  /***************************************************************************/

  getAllProjects() 
  {
    this.projectsService.getAllProjects().subscribe( data => {
      for (let entry of data)
      {
        this.projectList.push(entry);
        
        // check if new user is logging in for the first time then present change Name option
        //if (this.storageService.currentUser.includes("newUser_"))
        if (this.storageService.currentUser.indexOf('newUser_') >= 0)
        {
          this.changeNameIndicator = true;
          this.loadingSuccess = false;
        } 
        else {
          this.loadingSuccess = true;
        }
      }
    },
    // redirect to login page, if user is not logged in yet
    error => {
      if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });
  }

  /***************************************************************************/

  /**
   * Lifecycle hook - delete all subscriptions to avoid memory leak
   */
  ngOnDestroy() 
  {
    this.asmSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }

  /***************************************************************************/
  
  changeUserName()
  {
    var userObject = new Object 
    userObject = {
      'oldName' : this.oldName,
      'newName' : this.newName,
    }

    // post new User to server
    this.userService.changeUserName(<any>userObject).subscribe( (data:any) => {
      if (data['message'] == "User not found"){
        this.popupText = "User not found";
        this.showPopup = true;
      }
      else if (data['message'] == "User already exists")
      {
        this.popupText = "User already exists";
        this.showPopup = true;
      }
      else if (data['message'] == "OK" && data['name'] != null && data['name'] != ""){
        this.popupText = "Name changed";
        this.showPopup = true;
       
        this.changeNameIndicator = false;
        this.loadingSuccess = true;
        this.storageService.setCurrentUser(data['name']);
      }
      else {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    });
  }

  /***************************************************************************/
   
  /** set searchTerm to project which was selected in the dropdown menu */
  changeSelection(str: string) : void {
    this.searchTerm = str;
    this.chooseReminder = false;
  }

  /***************************************************************************/
   
  /** navigate to survey page with the chosen project of searchTerm */
  startSurvey() : void {
    this.assessmentSelection = true;
    this.sourceButton = "startSurvey";
    /*if (this.searchTerm == "")
    {
      this.chooseReminder = true;
      return;
    }

    this.isLoading = true;
    this.startTimer();
    this.storageService.getAssessment(this.searchTerm);
    this.navigationTerm = '/introduction';*/
    //this.router.navigate(['/introduction', this.searchTerm]);
  }

  /***************************************************************************/
   
  /** navigate to result page with the chosen project of searchTerm */
  startResults() : void {
    this.assessmentSelection = true;
    this.sourceButton = "startResults";
    /*if (this.searchTerm == "")
    {
      this.chooseReminder = true;
      return;
    }

    this.isLoading = true;
    this.startTimer();
    this.storageService.getAssessment(this.searchTerm);
    this.navigationTerm = '/result';*/
    //this.router.navigate(['/result', this.searchTerm]);
  }

  /***************************************************************************/
   
  /** navigate to result page with the chosen project of searchTerm */
  startReview() : void {
    this.assessmentSelection = true;
    this.sourceButton = "startReview";
    /*if (this.searchTerm == "")
    {
      this.chooseReminder = true;
      return;
    }
    
    this.isLoading = true;
    this.startTimer();
    this.storageService.getAssessment(this.searchTerm);
    this.navigationTerm = '/review';*/
    //this.router.navigate(['/result', this.searchTerm]);
  }

  /***************************************************************************/
   
  editSurvey(): void {
    this.router.navigate(['/edit-assessment']);
  }

  /***************************************************************************/
   
  createNewSurvey(): void {
    this.router.navigate(['/create-assessment']);
  }

  /***************************************************************************/
   
  distributeSurvey(): void {
    this.router.navigate(['/distribute-assessment']);
  }

  /***************************************************************************/
   
  acceptTerms(): void {
    console.log(this.storageService.currentUser);
    this.userService.acceptTerms("", this.storageService.currentUser).subscribe( (data:any) => {
      if (data['message'] == "OK"){
          this.termsIndicator = true;
      }
      else {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    });
  }

  /***************************************************************************/

  /** 
   * Fill progress bar every 0.02 Seconds 
   * if it is full, navigate to result page 
   */
  startTimer() {
    this.progressInterval = setInterval(() => {
    
      this.progressCounter = this.progressCounter + 1;

      if (this.progressCounter >= 100)
      {
        clearInterval(this.progressInterval);
        //this.isLoading = false;
        return;
      }
    },20)
  }

  /***************************************************************************/

  /** 
   * Fill progress bar every 0.02 Seconds 
   * if it is full, navigate to result page 
   */
  dangerPost() {
    //this.http.get('src/../template_englisch_final.json').subscribe(data => console.log(data));
    this.assessmentService.postAssessment(this.exData).subscribe((data: any) => 
    {
      if (data['message'] == "OK")
      {
        //this.assessmentService.calculateDependecies({},"Kasachstan").subscribe(data => console.log(data));
      }
    });
  }

  /***************************************************************************/

  /** 
   * Fill progress bar every 0.02 Seconds 
   * if it is full, navigate to result page 
   */
  dangerCalc() {
    //this.http.get('src/../template_englisch_final.json').subscribe(data => console.log(data));

        this.assessmentService.calculateDependecies({},"Kasachstan").subscribe(data => console.log(data));
  }

  /***************************************************************************/

  userIsCreator() {
    //if (this.storageService.currentUserType.includes('creator'))
    if (this.storageService.currentUserType.indexOf('creator') >= 0)
    {
      return true;
    }
    else {
      return false;
    }
    
  }

  /***************************************************************************/

  /** aback button on the child component was clicked - hide the child component */
  backEvent(eventString: string)
  {
    if (eventString == "back")
    {
      this.assessmentSelection = false;
    }
  }

  /***************************************************************************/

  /** Continue button on the child component was clicked */
  startEvent(eventString: string)
  {
    this.assessmentSelection = false;
    if (eventString == "")
    {
      this.popupText = "No project was chosen";
      this.showPopup = true;
      return;
    }

    this.searchTerm = eventString;
    this.isLoading = true;
    this.startTimer();
    this.navigationTerm = '/introduction';
    this.storageService.getAssessment(eventString);
  }

  /***************************************************************************/

  /** Continue button on the child component was clicked */
  resultsEvent(eventString: string)
  {
    this.assessmentSelection = false;
    if (eventString == "")
    {
      this.popupText = "No project was chosen";
      this.showPopup = true;
      return;
    }

    this.searchTerm = eventString;
    this.isLoading = true;
    this.startTimer();
    this.navigationTerm = '/result';
    this.storageService.getAssessment(eventString);
  }

  /***************************************************************************/

  /** Continue button on the child component was clicked */
  reviewEvent(eventString: string)
  {
    this.assessmentSelection = false;
    if (eventString == "")
    {
      this.popupText = "No project was chosen";
      this.showPopup = true;
      return;
    }
    
    this.searchTerm = eventString;
    this.isLoading = true;
    this.startTimer();
    this.navigationTerm = '/review';
    this.storageService.getAssessment(eventString);
  }

  /***************************************************************************/

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
  }

}
