import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AssessmentService } from '../swagger-typescript/api/assessment.service';
import { Assessment } from '../swagger-typescript/model/assessment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  /** uses to store the last HTTP request error */
  public currentError: Error = null;

  /** used to store the current userName */
  @Input() currentUser: string = "";
  /** used to store the current userType */
  @Input() currentUserType: Array<string> = [];
  /** used as return value for observable */
  @Input() asm: Assessment = null;
  /** used as return value for observable */
  @Input() error: Error = null;

  /** Observable Item sources as BehaviorSubject of currentUser */
  private _currentUserSource = new BehaviorSubject(this.currentUser);
  /** Observable Item sources as BehaviorSubject of currentUserType */
  private _currentUserTypeSource = new BehaviorSubject(this.currentUserType);
  /** Observable Item sources as BehaviorSubject of asm */
  private _asmSource = new BehaviorSubject(this.asm);
  /** Observable Item sources as BehaviorSubject of error */
  private _errorSource = new BehaviorSubject(this.error);

  /** Observable Item stream which converts _currentUserSource to an Observable (which other Components can subscribe to) */
  currentUserItem$ = this._currentUserSource.asObservable();
  /** Observable Item stream which converts _currentUserTypeSource to an Observable (which other Components can subscribe to) */
  currentUserTypeItem$ = this._currentUserTypeSource.asObservable();
  /** Observable Item stream which converts _asmSource to an Observable (which other Components can subscribe to) */
  asmItem$ = this._asmSource.asObservable();
  /** Observable Item stream which converts _errorSource to an Observable (which other Components can subscribe to) */
  errorItem$ = this._errorSource.asObservable();
  

  /** constructor */
  constructor(private assessmentService: AssessmentService,
             ) { }

  /***************************************************************************/
   
  /** Get all data for the assessment of the selected projectName */
  getAssessment(projectName: string)
  {
    // load assessment data
    this.assessmentService.getAssessment(projectName).subscribe( data => {
      if (data == null || data == undefined) 
      {
        alert("No assessment found for the chosen project name");
        return false;
      }
      this.asm = data;
      this._asmSource.next(this.asm);
    },
    // broadcast error if user is not logged in
    error => {
      if(error.status == 401)
      {
        this.error = error;
        this._errorSource.next(this.error);
      }
    });
  }

  /***************************************************************************/
   
  /** Get all data for the assessment of the selected projectName */
  getAssessmentById(projectId: string)
  {
    console.log("getAssessmentById");
    // load assessment data
    this.assessmentService.getAssessmentById(projectId).subscribe( data => {
      if (data == null || data == undefined) 
      {
        alert("No assessment found for the chosen project id");
        return false;
      }
      this.asm = data;
      this._asmSource.next(this.asm);
      console.log("getAssessmentById FOUND");
      
      // reset error
      this.error = null;
      this._errorSource.next
    },
    // broadcast error if user is not logged in
    error => {
      console.log("getAssessmentById ERROR");
      if(error.status == 401)
      { 
        this.error = error;
        this._errorSource.next(this.error);
      }
    });
  }

  /***************************************************************************/

  setCurrentUser(user: string)
  {
    this.currentUser = user;
    this._currentUserSource.next(this.currentUser);

    // reset error
    this.error = null;
    this._errorSource.next
  }

  /***************************************************************************/

  setCurrentUserType(userType: Array<string>)
  {
    this.currentUserType = userType;
    this._currentUserTypeSource.next(this.currentUserType);
  }

  /***************************************************************************/
   
  /** clear assessment data */
  resetAssessment() 
  {
    this.asm = null;
    this._asmSource.next(this.asm);
  }
  
}
