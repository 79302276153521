import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ChartsModule } from 'ng2-charts';
import { AppComponent } from './app.component';

import { UsersService } from 'src/swagger-typescript/api/users.service';
import { UserService } from 'src/swagger-typescript/api/user.service';
import { AssessmentService } from 'src/swagger-typescript/api/assessment.service';
import { ProjectsService } from 'src/swagger-typescript/api/projects.service';
import { RecommendationService } from 'src/swagger-typescript/api/recommendation.service';
import { LoginService } from 'src/swagger-typescript/api/login.service';
import { IdHandlerService } from 'src/swagger-typescript/api/idHandler.service';
import { TranslationService } from 'src/translation/translation.service'
import { StorageService } from 'src/app/storage.service'

import { LoginComponent } from './login/login.component';
import { SurveyComponent } from './survey/survey.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilterTextPipe } from './filter-text.pipe';
import { ResultComponent } from './result/result.component';
import { TranslatePipe } from '../translation/translate.pipe';
import { TRANSLATION_PROVIDERS } from '../translation/translations';
import { FilterTranslationPipe } from '../translation/filter-translation.pipe';
import { IntroductionComponent } from './introduction/introduction.component';
import { ReviewComponent } from './review/review.component';
import { SettingsComponent } from './settings/settings.component';
import { CreateAssessmentComponent } from './create-assessment/create-assessment.component';
import { EditAssessmentComponent } from './edit-assessment/edit-assessment.component';
import { DistributeAssessmentComponent } from './distribute-assessment/distribute-assessment.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PopupComponent } from './popup/popup.component';
import { RemoteReviewComponent } from './remote-review/remote-review.component';
import { AssessmentSelectionComponent } from './dashboard/assessment-selection/assessment-selection.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { CompareComponent } from './compare/compare.component';
import { OverviewAssessmentComponent } from './overview-assessment/overview-assessment.component'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SurveyComponent,
    DashboardComponent,
    FilterTextPipe,
    ResultComponent,
    TranslatePipe,
    FilterTranslationPipe,
    IntroductionComponent,
    ReviewComponent,
    SettingsComponent,
    CreateAssessmentComponent,
    EditAssessmentComponent,
    DistributeAssessmentComponent,
    ImprintComponent,
    PrivacyComponent,
    PopupComponent,
    RemoteReviewComponent,
    AssessmentSelectionComponent,
    ForgotPasswordComponent,
    CompareComponent,
    OverviewAssessmentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ChartsModule,
  ],
  providers: [UsersService,
              UserService,
              AssessmentService,
              ProjectsService,
              RecommendationService,
              LoginService,
              TranslationService,
              FilterTranslationPipe,
              TRANSLATION_PROVIDERS,
              TranslatePipe,
              StorageService,
              IdHandlerService,
             ],
  bootstrap: [AppComponent]
})
export class AppModule { }
