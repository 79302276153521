import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/swagger-typescript/api/users.service';
import { User } from 'src/swagger-typescript/model/User';
import { LoginService } from 'src/swagger-typescript/api/login.service';
import { UserService } from 'src/swagger-typescript/api/user.service';
import { StorageService } from 'src/app/storage.service'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  /** store username */
  private username: string = '';

  /** page is invisibale if server request for page materials is unsuccessful */
  public loadingSuccess: boolean = false;
  
  /** store password of the upper input field */
  private oldPassword: string = '';
  
  /** store password of the lower input field */
  private newPassword1: string = '';
  private newPassword2: string = '';

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  /** constructor */
  constructor(private usersService: UsersService,
              private userService: UserService,
              private router: Router,
              private storageService: StorageService
              ) { }

  /******************************************************************************/

  ngOnInit() {
    this.userService.getSessionUser().subscribe( data => {
      if (data != null && data['message'] != "")
      {
        this.storageService.setCurrentUser(data['message']);
        this.loadingSuccess = true;
      }
      else
      {
        this.router.navigate(['/login', 'login']);
      }
    },
    // redirect to login page, if user is not logged in yet
    error => {
      if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });

  }

  /******************************************************************************/

  /** send post request to login a user */
  changePassword() : void
  {
    // check if passwords in field one and two are matching
    if (this.newPassword1 == '' || this.newPassword2 == '')
    {
      this.popupText = "Please enter password";
      this.showPopup = true;
      return;
    }
    else if (this.newPassword1 != this.newPassword2)
    {
      this.popupText = "New Password fields do not match";
      this.showPopup = true;
      return;
    }

    var pwObject = new Object 
    pwObject = {
      'name' : this.storageService.currentUser,
      'oldPassword' : this.oldPassword,
      'newPassword' : this.newPassword1,
    }

    // post new User to server
    this.userService.changePassword(<any>pwObject).subscribe( (data:any) => {
      if (data['message'] == "User not found"){
        this.popupText = "User not found";
        this.showPopup = true;
      }
      else if (data['message'] == "Old password wrong"){
        this.popupText = "Old password wrong";
        this.showPopup = true;
      }
      if (data['message'] == "New password invalid"){
        this.popupText = "New password invalid";
        this.showPopup = true;
      }
      else if (data['message'] == "Success"){
        this.popupText = "Password changed";
        this.showPopup = true;
        this.router.navigate(["/dashboard"]);
      }
      else {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    });
  }

  /***************************************************************************/

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
  }

}
