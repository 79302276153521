import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AssessmentService } from '../../swagger-typescript/api/assessment.service';
import { StorageService } from '../storage.service';
import { Assessment } from '../../swagger-typescript/model/assessment';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslationService } from '../../translation/translation.service';
import { Categorie } from '../../swagger-typescript/model/categorie';
import { FilterTranslationPipe } from '../../translation/filter-translation.pipe'
import { Subscription } from 'rxjs';
import { Question, RelevancyAnswer } from 'src/swagger-typescript';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  /** used to store the assessment retrieved from the DB */
  private asm: Assessment = null;

  /** used to store project name */
  private projectName: string = "";

  /** used to navigate to the first question of a category */
  private categoryNav: string = "";

  /** indicate which question is currently answered */
  private currentQuestion: number = 0;

  /** Used to store all category names */
  private categoryArray: Categorie[] = [];

  /** Subscription for Assessments from the DB */
  private asmSubscription: Subscription;

  /** Subscription for Errors from the DB-conenction */
  private errorSubscription: Subscription;

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  @ViewChild('selectElement', {read: ElementRef, static: false}) selectElement: ElementRef;

  /** constructor */
  constructor(private assessmentService: AssessmentService,
              private router: Router,
              private route: ActivatedRoute,
              private translator: TranslationService,
              private filterPipe: FilterTranslationPipe,
              private storageService: StorageService,
              ) { }

  /***************************************************************************/

  /**
   * Lifecycle hook - triggered on page initialization
   * Load chosen assessment and display selected question
   */
  ngOnInit() {

    // (+) before params turns the string into a number
    //this.currentQuestion = +this.route.snapshot.paramMap.get('question');
    this.projectName = this.route.snapshot.paramMap.get('project');
    this.categoryNav = this.route.snapshot.paramMap.get('category');
    
    this.asmSubscription = this.storageService.asmItem$
    .subscribe((data: Assessment) => 
    {
      console.log(data);
      // no assessment found for that project name
      if (data == false || data == null || data == undefined) 
      {
        //alert('No assessment found for the chosen project name');
        return;
      }

      this.asm = data;
      this.categoryArray = [];

      // fill category array 
      for (let questionCats of this.asm.questions)
      {
        this.categoryArray.push(questionCats.categories[0]);
      }

      this.categoryArray = this.categoryArray.filter(this.uniqueFilter); 

      // navigate to first question of the category
      if (this.categoryNav != '')
      {
        for (var i = 0; i < this.asm.questions.length; i++)
        {
          if (this.asm.questions[i].categories[0].name == this.categoryNav)
          {
            this.currentQuestion = i;
            //this.selectElement.nativeElement.children[0].selected = true;
            break;
          }
        }
      }
    });

    // navigate to login page, if server responds with status 401 to assessment request
    this.errorSubscription = this.storageService.errorItem$
    .subscribe((error: any) => 
    {
      if (error == null)
      {
        return;
      }
      else if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });

    //this.storageService.getAssessment(this.projectName);

    // load assessment data
    /*this.assessmentService.getAssessment(this.projectName).subscribe( data => {
      if (data == null || data == undefined) 
      {
        alert('No assessment found for the chosen project name');
        return;
      }
      this.asm = data;

      // fill category array 
      for (let questionCats of this.asm.questions)
      {
        this.categoryArray.push(questionCats.categories[0]);
      }

      this.categoryArray = this.categoryArray.filter(this.uniqueFilter); 

      // navigate to first question of the category
      if (this.categoryNav != '')
      {
        for (var i = 0; i < this.asm.questions.length; i++)
        {
          if (this.asm.questions[i].categories[0].name == this.categoryNav)
          {
            this.currentQuestion = i;
            //this.selectElement.nativeElement.children[0].selected = true;
            break;
          }
        }
      }

    },
    // redirect to login page, if user is not logged in yet
    error => {
      if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });*/
  }

  /***************************************************************************/
   
  /**
   * Lifecycle hook - delete all subscriptions to avoid memory leak
   */
  ngOnDestroy() 
  {
    this.asmSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }

  /***************************************************************************/

  /** toggle comment pop up */
  toggleComment(event: Event, i?: number) {
    
    const hasClass = (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.contains('isVisible');
    if (hasClass)
    {
      (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.remove('isVisible');
      (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.add('isNotVisible');
      (<HTMLInputElement>event.currentTarget).children[0].innerHTML = "+";
    }
    else 
    {
      (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.remove('isNotVisible');
      (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.add('isVisible');
      (<HTMLInputElement>event.currentTarget).children[0].innerHTML = "-";
    }
  }

  /***************************************************************************/

  /**
   * check if user is allowed to make changes
   */
  checkEditRights()
  {
    // check if user is allowed to make changes
    var matchFound = false;
    for (let userRight of this.asm.editRights)
    {
      if (userRight.name == this.storageService.currentUser)
      {
        matchFound = true;
      }
    }
    if (matchFound == false)
    {
        this.popupText = "You do not have edit rights for this assessment";
        this.showPopup = true;
        return false;
    }
  }

  /***************************************************************************/

  /**
   * Select multiple answers to the questioin
   */
  selectAnswer(index: number) 
  {
    this.checkEditRights();

    // select / deselect the clicked answer
    if (this.asm.questions[this.currentQuestion].answers[index].isSelected == true)
    {
      this.asm.questions[this.currentQuestion].answers[index].isSelected = false;
    }
    else 
    {
      this.asm.questions[this.currentQuestion].answers[index].isSelected = true;
    } 
  }

  /***************************************************************************/

  /**
   * Select ONE answer to the relevancy
   */
  selectRelevancy(index: number) 
  {
    this.checkEditRights();  

    // select / deselect the clicked answer
    if (this.asm.questions[this.currentQuestion].relevancies[0].answers[index].isSelected == true)
    {
      this.asm.questions[this.currentQuestion].relevancies[0].answers[index].isSelected = false;
    }
    else 
    {
      this.asm.questions[this.currentQuestion].relevancies[0].answers[index].isSelected = true;
    }

    // loop through all answers and deselect all that were not clicked
    for (var k = 0; k < this.asm.questions[this.currentQuestion].relevancies[0].answers.length; k++)
    {
      if (k != index)
      {
        this.asm.questions[this.currentQuestion].relevancies[0].answers[k].isSelected = false;
      }
    }  
  }

  /***************************************************************************/

  /**
   * navigate to the next question
   */
  next() : void 
  {
    // do not navigate to next question, if changes could not be saved
    if (this.currentQuestion < (this.asm.questions.length - 1))
    {
      if (this.saveChanges() == false)
      {
        this.popupText = "Changes could not be saved";
        this.showPopup = true;
        return;
      }
      this.currentQuestion = this.currentQuestion + 1;
      this.selectElement.nativeElement.children[0].selected = true;
    }
    else
    {
      if (this.saveChanges() == false)
      {
        this.popupText = "Changes could not be saved";
        this.showPopup = true;
        return;
      }
      else {
        this.router.navigate(['/result/' + this.asm.projects[0].name ]);
      }
    }
  }

  /***************************************************************************/

  /**
   * navigate to the last question
   */
  back() : void 
  {
    // do not navigate to next question, if changes could not be saved
    if (this.currentQuestion > 0)
    {
      if (this.saveChanges() == false)
      {
        this.popupText = "Changes could not be saved";
        this.showPopup = true;
        return;
      }
      this.currentQuestion = this.currentQuestion - 1;
      this.selectElement.nativeElement.children[0].selected = true;
    }
    else 
    {
      this.router.navigate(['/introduction/' + this.asm.projects[0].name ]);
    }
  }

  /***************************************************************************/

  /** navigate to result page */
  showResult()
  {
    this.router.navigate(['/result/' + this.asm.projects[0].name ]);
  }

  /***************************************************************************/

  /** navigate to introduction page */
  showIntro()
  {
    this.router.navigate(['/introduction/' + this.asm.projects[0].name ]);
  }

  /***************************************************************************/

  /** navigate to introduction page */
  showReview()
  {
    this.router.navigate(['/review/' + this.asm.projects[0].name ]);
  }

  /***************************************************************************/

  /**
   * navigate to the next question
   */
  changeCategory(str: any) : void 
  {
    // navigate to first question of the category 
    for (var i = 0; i < this.asm.questions.length; i++)
    {
      var text = this.filterPipe.transform(this.asm.questions[i].categories[0].text, this.translator.currentLang);
      if (text == str)
      {
        this.router.navigate(['/surveys/' + this.asm.projects[0].name + '/' + this.asm.questions[i].categories[0].name]);
        this.currentQuestion = i; 
        this.selectElement.nativeElement.children[0].selected = true;
        break;
      }
    }
  }

  /***************************************************************************/

  /**
   * save changes to the question of the assessment
   */
  saveChanges() : any
  {
    this.assessmentService.updateQuestion(this.asm.questions[this.currentQuestion], this.asm._id,this.asm.questions[this.currentQuestion]._id).subscribe(data => {
      if (data['message'] != 'OK')
      {
        this.popupText = "Changes could not be saved";
        this.showPopup = true;
        return false;
      }
      return true;
    });
  }

  /***************************************************************************/
    
  /**
   * Used to filter out multible entries in an array 
   * only unique ones remain in the array
   * @param value current value of the array
   * @param index current index
   * @param self input array (?)
   */
  uniqueFilter(value: Categorie, index, self) { 
    if (index == (self.length - 1)) return true;
    else if (self[index].name == self[index + 1].name) return false;
    return true;
  }

  /***************************************************************************/

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
  }

  /***************************************************************************/
    
  /**  */
  getCommentLanguageIndex(question: Question) {
    var lang = this.translator.currentLang;
    for (let comment of question.comment)
    {
      if (comment.lang == lang)
      {
        return question.comment.indexOf(comment);
      }
    }

    return 0;
  }

  /***************************************************************************/
    
  /** */
  getRelevancyCommentLanguageIndex(answer: RelevancyAnswer) {
    var lang = this.translator.currentLang;
    for (let comment of answer.comment)
    {
      if (comment.lang == lang)
      {
        return answer.comment.indexOf(comment);
      }
    }

    return 0;
  }

}
