import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/app/login/login.component'
import { SurveyComponent } from 'src/app/survey/survey.component'
import { DashboardComponent } from 'src/app/dashboard/dashboard.component'
import { ResultComponent } from 'src/app/result/result.component'
import { IntroductionComponent } from 'src/app/introduction/introduction.component'
import { ReviewComponent } from 'src/app/review/review.component'
import { SettingsComponent } from 'src/app/settings/settings.component'
import { CreateAssessmentComponent } from 'src/app/create-assessment/create-assessment.component'
import { EditAssessmentComponent } from 'src/app/edit-assessment/edit-assessment.component'
import { DistributeAssessmentComponent } from 'src/app/distribute-assessment/distribute-assessment.component'
import { ImprintComponent } from 'src/app/imprint/imprint.component'
import { PrivacyComponent } from 'src/app/privacy/privacy.component'
import { RemoteReviewComponent } from 'src/app/remote-review/remote-review.component'
import { CompareComponent } from './compare/compare.component';
import { OverviewAssessmentComponent } from './overview-assessment/overview-assessment.component';


const routes: Routes = [
  { path: 'login/:type', component: LoginComponent },
  { path: 'login/:type/:id', component: LoginComponent },
  { path: 'surveys/:project', component: SurveyComponent },
  { path: 'surveys/:project/:category', component: SurveyComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'result/:project', component: ResultComponent },
  { path: 'introduction/:project', component: IntroductionComponent },
  { path: 'review/:project', component: ReviewComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'create-assessment', component: CreateAssessmentComponent },
  { path: 'edit-assessment', component: EditAssessmentComponent },
  { path: 'distribute-assessment', component: DistributeAssessmentComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'remoteReview', component: RemoteReviewComponent },
  { path: 'remoteReview/:id', component: RemoteReviewComponent },
  { path: 'compare', component: CompareComponent },
  { path: 'overview-assessment', component: OverviewAssessmentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    { scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],}
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
