import { Injectable, Inject, EventEmitter } from '@angular/core';
import { TRANSLATIONS } from './translations';

/**
 * Service to provide text translations for the app
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationService 
{
  /** current language */
  private _currentLang: string; 

  /** default language (in this case english as base for all translations) */
  private _defaultLang: string = 'de';

  /** fallback language, if translation goes wrong (depending on the application) */
  private _fallback: boolean;

  /** placeholder for parameters in translation */
  private PLACEHOLDER = '%'; 

  /** Event Object which is emitted when the language is changed on the HMI */
  public onLangChanged: EventEmitter<string> = new EventEmitter<string>();

  /** List of possibly available languages */
  public langList: string[] = ["de", "en"];
  
  /**********************************************************************************************/

  /** contructor with the injected translations */
  constructor(@Inject(TRANSLATIONS) private _translations: any,) 
  {

  }
  
  /**********************************************************************************************/

  /** 
   * Get the current language selected for the App
   * 
   * @return _currentLang
   */
  public get currentLang() 
  {
    return this._currentLang || this._defaultLang;
  }

  /**********************************************************************************************/

  /** 
   * change the current language by emitting the changed language (to inform this APP) 
   * and sending the language Change to the AGV and save the chosen 
   * 
   * @param {string} lang language abbreviation which should be used 
   */
  public use(lang: string): void 
  {
    // language changed
    if(this._currentLang !== lang) 
    { 
      // set current language
      this._currentLang = lang;
      this.onLangChanged.emit(lang);
      console.log(this._currentLang);
    } 
  }
  
  /**********************************************************************************************/

  /** 
   * Set default language 
   * 
   * @param {string} lang lagnuage abbreviation which should be used 
   */
  public setDefaultLang(lang: string) {
    this._defaultLang = lang;
  }
  
  /**********************************************************************************************/

  /** 
   * enable of fallback language
   * 
   * @param {boolean} enable lagnuage abbreviation which should be used 
   */
  public enableFallback(enable: boolean) {
    // enable or disable fallback language
    this._fallback = enable; 
  }

  /**********************************************************************************************/

  /**
   * translate in the input string current language, if not possible, translate it to 
   * the fallback language. This works by looking up the mapped keys (strings) in the
   * lang-XX.ts files
   * 
   * @param {string} key translation key which can be found in the lang-XX.ts files
   * 
   * @return translated string 
   */
  private translate(key: string): string 
  {
    // private perform translation
    let translation = key;

    if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
      return this._translations[this.currentLang][key];
    }
    else if(this._fallback && this._translations[this._defaultLang] && this._translations[this._defaultLang][key]) {
      return this._translations[this._defaultLang][key];
    }

    return translation;
  }
  
  /**********************************************************************************************/
  
  /**
   * calls this.translate(key)
   * 
   * @return this.replace(translation, params)
   */
  public instant(key: string, params?: string | string[]) {
    // call translation
    const translation: string = this.translate(key);

    if (!params) return translation;
    return this.replace(translation, params);
  }

  /**********************************************************************************************/

  /**
   * concat params to the input text
   * 
   * @param {string} text input string
   * 
   * @param {string | string[]} params string or string array whoch should be concated to "text"
   * 
   * @return concated string
   */
  public replace(text: string = '', params: string | string[] = '') {
      let translation: string = text;

      const values: string[] = [].concat(params);
      values.forEach((e, i) => {
          translation = translation.replace(this.PLACEHOLDER.concat(<any>i), e);
      });

      return translation;
  }
  
}
