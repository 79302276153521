/**
 * Digital assessments
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Assessment } from '../model/assessment';
import { Question } from '../model/question';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AssessmentService {

    protected basePath = 'https://digital-assessments.ipa.cell.vfk.fraunhofer.de';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a new Assessment
     * 
     * @param body USE EMPTY OBJECT
     * @param projectName name of project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateDependecies(body: Assessment, projectName: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public calculateDependecies(body: Assessment, projectName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public calculateDependecies(body: Assessment, projectName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public calculateDependecies(body: Assessment, projectName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling calculateDependecies.');
        }

        if (projectName === null || projectName === undefined) {
            throw new Error('Required parameter projectName was null or undefined when calling calculateDependecies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Survey/calculateDependencies/${encodeURIComponent(String(projectName))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Assessment
     * 
     * @param project project name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssessment(project: string, observe?: 'body', reportProgress?: boolean): Observable<Assessment>;
    public getAssessment(project: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Assessment>>;
    public getAssessment(project: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Assessment>>;
    public getAssessment(project: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (project === null || project === undefined) {
            throw new Error('Required parameter project was null or undefined when calling getAssessment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Assessment>('get',`${this.basePath}/Survey/${encodeURIComponent(String(project))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Assessment
     * 
     * @param id assessment id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssessmentById(id: string, observe?: 'body', reportProgress?: boolean): Observable<Assessment>;
    public getAssessmentById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Assessment>>;
    public getAssessmentById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Assessment>>;
    public getAssessmentById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAssessmentById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Assessment>('get',`${this.basePath}/Survey/getAssessmentById/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Assessment
     * 
     * @param body Add assessment to DB
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAssessment(body: Assessment, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postAssessment(body: Assessment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postAssessment(body: Assessment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postAssessment(body: Assessment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling postAssessment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Survey`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Assessment
     * 
     * @param id assessment id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestReview(id: string, observe?: 'body', reportProgress?: boolean): Observable<Assessment>;
    public requestReview(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Assessment>>;
    public requestReview(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Assessment>>;
    public requestReview(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling requestReview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Assessment>('get',`${this.basePath}/Survey/requestReview/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * setTestRights
     * 
     * @param body USE EMPTY OBJECT
     * @param projectName name of project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setTestRights(body: Assessment, projectName: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setTestRights(body: Assessment, projectName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setTestRights(body: Assessment, projectName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setTestRights(body: Assessment, projectName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setTestRights.');
        }

        if (projectName === null || projectName === undefined) {
            throw new Error('Required parameter projectName was null or undefined when calling setTestRights.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Survey/setTestRights/${encodeURIComponent(String(projectName))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Assessment
     * 
     * @param body Add assessment to DB
     * @param asmId id of the assessment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAllAssessmentQuestions(body: Assessment, asmId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateAllAssessmentQuestions(body: Assessment, asmId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateAllAssessmentQuestions(body: Assessment, asmId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateAllAssessmentQuestions(body: Assessment, asmId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAllAssessmentQuestions.');
        }

        if (asmId === null || asmId === undefined) {
            throw new Error('Required parameter asmId was null or undefined when calling updateAllAssessmentQuestions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Survey/UpdateAllAssessmentQuestions/${encodeURIComponent(String(asmId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Assessment
     * 
     * @param body Update entire assessment in DB
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAssessment(body: Assessment, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateAssessment(body: Assessment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateAssessment(body: Assessment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateAssessment(body: Assessment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateAssessment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Survey/UpdateAssessment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update question of existing Assessment
     * 
     * @param body Add assessment to DB
     * @param asmId id of the assessment
     * @param questionId id of the question
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateQuestion(body: Question, asmId: string, questionId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateQuestion(body: Question, asmId: string, questionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateQuestion(body: Question, asmId: string, questionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateQuestion(body: Question, asmId: string, questionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateQuestion.');
        }

        if (asmId === null || asmId === undefined) {
            throw new Error('Required parameter asmId was null or undefined when calling updateQuestion.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling updateQuestion.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Survey/updateQuestion/${encodeURIComponent(String(asmId))}/${encodeURIComponent(String(questionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
