import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  /** question which is displayed set by the parent component */
  @Input()  popupText: string;
  /** left button text which is displayed set by the parent component */
  @Input()  buttonLeftText: string;
  /** right button text which is displayed set by the parent component */
  @Input()  buttonRightText: string;
  /** center button text which is displayed set by the parent component */
  @Input()  buttonCenterText: string;

  /** event Object which emits the question answer to the parent component, when it's fired */
  @Output() answerEvent = new EventEmitter<string>();

  /** consturctor */
  constructor( 
             ) { }


  /**********************************************************************************************/

  /** 
   * Lifecycle hook ngOnInit is triggered when the component is initialized.
   */
  ngOnInit(): void 
  {
  }


  /**********************************************************************************************/

  /** event which emits the Event Object to the parent component, when it's fired (not used atm)  
   * 
   * @param {string} answer answer which should be emitted 
   */
  emitAnswer(answer: string)
  {
    this.answerEvent.emit(answer);
  }

}
