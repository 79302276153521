import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from './translation.service'; // our translate service

/** pipe which is used to translate text */
@Pipe({
    name: 'translate',
    pure: false
})

/** pipe which is used to translate text */
export class TranslatePipe implements PipeTransform {

    /** constructor */
    constructor(private _translate: TranslationService) { }

    /** 
     * tranform function to change input string to a predefined translation provided by the {@link TranslateService} 
     * 
     * @param {string} value input string
     * 
     * @param {string | string[]} args args
     * 
     * @return {any} return translated string
     */
    transform(value: string, args: string | string[]): any 
    {
        if (!value) return;
        return this._translate.instant(value, args);
    }
}
