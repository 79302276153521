// translations
export const LANG_DE_TRANS = {

    // applicationwide
    'Assessment':                'Assessment',
    'Topics':                    'Themen',
    'Topic':                     'Thema',
    'Result':                    'Ergebnis',
    'Imprint':                   'Impressum',
    'Privacy':                   'Datenschutz',
    'Manage assessments':        'Assessments verwalten',
    'Settings':                  'Einstellungen',

    //introduction
    'Jump to category':          'Zu Kategorie springen',
    'First question':            'Erste Frage',
    'Introduction':              'Einleitung',

    //result
    'save':                      'speichern',
    'current state':             'Ist-Zustand',
    'target state':              'Soll-Zustand',
    'relevance':                 'Relevanz', 
    'Request a Review':          'Review anfragen',
    'recommendation':            'Empfehlung',

    //survey
    'Question':                  'Frage',
    'of':                        'von',
    'Choose assessment':         'Assessment wählen',
    'Calculate results':         'Ergebnis berechnen',
    'Next question':             'Nächste Frage',
    'Last question':             'Vorherige Frage',

    //assessment selection
    'Start Assessment for Project':     'Starte Assessment für Projekt',
    'Look at results':                  'Ergebnis ansehen',
    'Start assessment':                 'Assessment starten',
    'Start review':                     'Review starten',
    'Choose project name':              'Projekt auswählen',
    'Edit assessment':                  'Assessment editieren',
    'Deploy assessment':                'Assessment verteilen',
    'New assessment':                   'Neues Assessment',

    //login
    'Username':         'Benutzername',        
    'Password':         'Passwort',        
    'Repeat password':  'Passwort wiederholen', 
    'Email':            'Email',           
    'Login':            'Anmelden',           
    'Logout':           'Abmelden',           
    'Register':         'Registrieren', 
    'To':               'Zum',

    // create / edit assessments
    'Introduction Text':            'Einleitungstext', 
    'Assessment Title':             'Assessment-Titel', 
    'Load Assessment':              'Assessment laden', 
    'Language':                     'Sprache', 
    'Category Name':                'Kategoriename',
    'Sub Category Name':            'Sub-Kategoriename', 
    'Question Text':                'Fragentext',  
    'Answer Text':                  'Antwortext',  
    'Answer':                       'Antwort',  
    'Relevancy':                    'Relevanz',  
    'Standard score':               'Standardgewichtung',  
    'Add answer':                   'Antwort hinzufügen',  
    'Add relevancy answer':         'Relevanzantwort hinzufügen',  
    'Add relation':                 'Verknüpfung hinzufügen',  
    'Add recommendation':           'Empfehlung hinzufügen',  
    'Add question':                 'Frage hinzufügen',  
    'Add sub category':             'Sub-Kategorie hinzufügen',  
    'Add category':                 'Kategorie hinzufügen',  
    'Save':                         'Speichern',  
    'Assessment is a Template':     'Assessment ist ein Template für weitere Assessments',

    // discribute assessment
    'Distribute to Mail Address':               'An Mail Adressen verteilen',  
    'View rights':                              'Ansichtsrechte',  
    'Edit rights':                              'Bearbeitungsrechte',  
    'User Type':                                'Benutzertyp',  
    'Basic user rights':                        'Standard Benutzerrechte',  
    'Creator rights':                           'Erstellerrechte',  
    'Send':                                     'Senden',
    'Seperate multiple adresses with a SPACE':  'Mehrere Adressen mit einem LEERZEICHEN trennen',

    // settings
    'Old password':                 'Altes Passwort',  
    'New password':                 'Neues Passwort',  
    'Repeat new password':          'Neues Passwort wiederholen',  
    'Change password':              'Passwort ändern', 

};