import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '../translation/translation.service';
import { LoginService, UserService } from 'src/swagger-typescript';
import { StorageService } from './storage.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: string = 'digitale-assessments';
  menuIsOpen: boolean = false;
  userIsLoggedIn: string = "";
  loginText: string = "Logout";
  currentUser: string = "";

  currentUserSubscription: Subscription;

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  constructor (private http: HttpClient,
               private router: Router,
               private route: ActivatedRoute,
               private translator: TranslationService,
               private loginService: LoginService,
               private userService: UserService,
               private storageService: StorageService )  
  {}

  /******************************************************************************/

  ngOnInit()
  {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      console.log(params['remoteReview']);
      console.log(params['id']);
      if (params['remoteReview'] != undefined && params['remoteReview'] == "true" && params['id'] != undefined)
      {
        this.router.navigate(['/remoteReview', params['id']]);
        return;
      }
      else if (window.location.href == "https://digital-assessments.ipa.cell.vfk.fraunhofer.de/")
      {
        this.router.navigate(['/dashboard']);
      }
    });

    this.userService.getSessionUser().subscribe( data => {
      if (data != null && data['message'] != "")
      {
        this.storageService.setCurrentUser(data['message']);
      }
    });

    this.userService.getSessionUserType().subscribe( data => {
      if (data != null && data['message'] != "")
      {
        this.storageService.setCurrentUserType(data['message']);
      }
    });

    // load assessment data
    this.currentUserSubscription = this.storageService.currentUserItem$
    .subscribe((data: any) => 
    {
      this.currentUser = data;
      if (this.currentUser == "")
      {
        this.loginText = "Login";
      }
      else
      {
        this.loginText = "Logout";
      }
    });

    //this.router.navigate(['/create-assessment']);  // TODO nur zum, test drin
  }

  /******************************************************************************/

  ngOnDestroy()
  {
    this.currentUserSubscription.unsubscribe();
  }

  /******************************************************************************/

  /** 
   * change language 
   * 
   * @param {string} newLanguage language abbreviation of the lang which should be change to 
   */
  changeLanguage(newLanguage: string): void {
    this.translator.use(newLanguage);
  }

  /******************************************************************************/

  /** toggle menu */
  openMenu() {
    if (this.menuIsOpen)
    {
      this.menuIsOpen = false;
    }
    else {
      this.menuIsOpen = true;
    }
  }

  /******************************************************************************/

  /** send post request to logout the current user */
  menuLogout() : void
  {
    this.menuIsOpen = false;

    if (this.currentUser == "")
    {
      this.router.navigate(['/login', 'login']);
    }
    else 
    {
      this.loginService.logout({_id:"", name:"", password:"", mail:""}).subscribe( (data:any) => {
      
        if (data['message'] == "Logout failed"){
          this.popupText = "Logout failed";
          this.showPopup = true;
        }
        else if (data['message'] == "Success"){
          this.storageService.setCurrentUser("");
          this.router.navigate(['/login', 'login']);
        }
        else {
          this.popupText = "Something went wrong";
          this.showPopup = true;
        }
      },
      error => {
        if(error.status == 500)
        {
          this.popupText = "Logout failed";
          this.showPopup = true;
        }
      });
    }
  }

  /******************************************************************************/

  /** toggle menu */
  navSettings() {
    if (this.menuIsOpen)
    {
      this.menuIsOpen = false;
    }
    else {
      this.menuIsOpen = true;
    }

    this.router.navigate(['/settings']);
  }

  /******************************************************************************/

  /** toggle menu */
  navAssessments() {
    if (this.menuIsOpen)
    {
      this.menuIsOpen = false;
    }
    else {
      this.menuIsOpen = true;
    }
    
    this.router.navigate(['/dashboard']);
  }

  /***************************************************************************/

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
  }

}
