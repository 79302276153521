import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/swagger-typescript';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  /** display text if password was forgotten */
  public forgotPasswordText: string = "A new password will be send to your mail address";

  /** store username */
  private username: string = '';
  
  /** store email adress */
  private mail: string = '';

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  /** event Object which emits the question answer to the parent component, when it's fired */
  @Output() forgotPasswordEvent = new EventEmitter<string>();

  constructor(private loginService: LoginService) { }

  ngOnInit() {
  }

  /******************************************************************************/

  /** send post request to login a user */
  requestNewPassword() : void
  {
    // check if passwords in field one and two are matching
    if (this.mail == '' || this.username == '')
    {
      this.popupText = "Please enter credentials";
      this.showPopup = true;
      return;
    }

    var user = new Object 
    user = {
      'name' : this.username,
      'mail' : this.mail,
      'password' : "",
    }

    this.loginService.requestNewPassword(<any>user).subscribe( (data:any) => {
      
      if (data['message'] == "Wrong username" || data['message'] == "Wrong password"){
        this.popupText = "Wrong input credentials";
        this.showPopup = true;
      }
      else if (data['message'] == "Success"){

        this.popupText = "Please check your email. You should have received a new password";
        this.showPopup = true;   
      }
      else {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    },
    error => {
      if(error.status == 401)
      {
        this.popupText = "Wrong input credentials";
        this.showPopup = true;
      }
    });
  }

  /**********************************************************************************************/

  /** event which emits the Event Object to the parent component, when it's fired
   * 
   * @param {string} eventStr event string which should be emitted 
   */
  emitforgotPassword(eventStr: string)
  {
    this.forgotPasswordEvent.emit(eventStr);
  }

  /***************************************************************************/

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
  }

  /***************************************************************************/

  navigateToLogin(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
    this.emitforgotPassword("");
  }

}
