export * from './assessment.service';
import { AssessmentService } from './assessment.service';
export * from './idHandler.service';
import { IdHandlerService } from './idHandler.service';
export * from './login.service';
import { LoginService } from './login.service';
export * from './projects.service';
import { ProjectsService } from './projects.service';
export * from './recommendation.service';
import { RecommendationService } from './recommendation.service';
export * from './relevancies.service';
import { RelevanciesService } from './relevancies.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AssessmentService, IdHandlerService, LoginService, ProjectsService, RecommendationService, RelevanciesService, UserService, UsersService];
