import { ApplicationRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/swagger-typescript/api/users.service';
import { User } from 'src/swagger-typescript/model/User';
import { LoginService } from 'src/swagger-typescript/api/login.service';
import { UserService } from 'src/swagger-typescript/api/user.service';
import { StorageService } from 'src/app/storage.service'
import { Assessment, ProjectsService, Project } from 'src/swagger-typescript';
import { Subscription } from 'rxjs';
import { error } from 'protractor';
import { prepareEventListenerParameters } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-distribute-assessment',
  templateUrl: './distribute-assessment.component.html',
  styleUrls: ['./distribute-assessment.component.css']
})
export class DistributeAssessmentComponent implements OnInit {

  /** store chosen Assesment in it */
  private asm: Assessment = null;
  
  /** store username */
  private username: string = '';

  /** page is invisibale if server request for page materials is unsuccessful */
  public loadingSuccess: boolean = false;
  
  /** Subscription for Assessments from the DB */
  private asmSubscription: Subscription;

  /** Subscription for Errors from the DB-conenction */
  private errorSubscription: Subscription;

  /** stores all assessment projects in an array */
  private projectList : Project[] = [];
  
  private usersString : string = "";

  private mailsString: string = "";

  public addressList: string[] = [];

  /** indicate theat data is beeing retrieved from the DB */
  private isSending : boolean = false;

  /** used to fill progressbar continiously */
  private progressCounter: number = 0; 

  /** used to set cyclic interval for progressbar */
  public progressInterval;

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  private resultViewRight: boolean = false;
  private resultEditRight: boolean = false;
  private reviewViewRight: boolean = false;
  private reviewEditRight: boolean = false;
  private assessmentViewRight: boolean = false;
  private assessmentEditRight: boolean = false;
  private userRight: boolean = false;
  private creatorRight: boolean = false;


  /** constructor */
  constructor(private usersService: UsersService,
              private userService: UserService,
              private router: Router,
              private storageService: StorageService,
              private projectsService: ProjectsService,
              private appRef: ApplicationRef
              ) { }

  /******************************************************************************/

  ngOnInit() {
    this.projectsService.getAllProjects().subscribe( data => {
      for (let entry of data)
      {
        this.projectList.push(entry);
        //this.loadingSuccess = true;
      }
    },
    // redirect to login page, if user is not logged in yet
    error => {
      if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });

    // load assessment data
    this.asmSubscription = this.storageService.asmItem$
    .subscribe((data: any) => 
    {
      // no assessment found for that project name
      if (data == false || data == null || data == undefined) 
      {
        return;
      }

      this.asm = data;
    });

    // navigate to login page, if server responds with status 401 to assessment request
    this.errorSubscription = this.storageService.errorItem$
    .subscribe((error: any) => 
    {
      if (error == null)
      {
        return;
      }
      else if(error.status == 401)
      {
        //this.isLoading = false;
        this.router.navigate(['/login', 'login']);
      }
      //this.isLoading = false;
    });

    this.addressList.push(" ");
  }

  /******************************************************************************/

  ngOnDestroy()
  {
    this.errorSubscription.unsubscribe();
    this.asmSubscription.unsubscribe();
  }
  
  /******************************************************************************/

  /** send post request to login a user */
  sendAssessment() : void
  {
    // start timer and hide loading bar when timer is up
    this.startTimer();
    this.isSending = true;

    // TODO maybe incorporate rights directly into asm

    var rightObject =  {"resultViewRight": this.resultViewRight,
                        "resultEditRight": this.resultEditRight,
                        "reviewViewRight": this.reviewViewRight,
                        "reviewEditRight": this.reviewEditRight,
                        "assessmentViewRight":this.assessmentViewRight,
                        "assessmentEditRight":this.assessmentEditRight,
                        "userRight":this.userRight,
                        "creatorRight":this.creatorRight };

    for(let adr of this.addressList)
    {
      // remove all white spaces from address
      adr = adr.replace(/\s/g, '');

      if (adr != undefined && adr != "")
      {
        this.mailsString = this.mailsString + " " + adr;
      }
    }

    var postObject = { "assessmentId": this.asm._id, "mailString": this.mailsString, "rightsObject": rightObject };

    // send mail string, assessment id and object with all rightes
    this. userService.distributeAssessment(postObject).subscribe( (data:any) => {
      if (data['message'] == "Assessment not found"){
        this.popupText = "Assessment not found";
        this.showPopup = true;
      }
      else if (data['message'] == "OK"){
        this.popupText = "Assessment distributed";
        this.showPopup = true;
      }
      else if (data['message'] == "New password invalid"){
        this.popupText = "No Assessment chosen";
        this.showPopup = true;
      }
      else if (data['message'] == "Success"){
        this.popupText = "New user registered";
        this.showPopup = true;
      }
      else {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
      this.isSending = false;
      this.progressCounter = 0;
    },
    error => {
      this.popupText = "Something went wrong";
      this.showPopup = true;
      this.isSending = false;
      this.progressCounter = 0;
    });
  }

  /***************************************************************************/

  addAddress()
  {
    this.addressList.push(" ");

    // force rerender
    /*this.progressInterval = setTimeout(() => {
      this.appRef.tick();
    }, 50)*/
    
  }

  /***************************************************************************/

  changeMailAddress(eventStr, adrIndex)
  {
    /*if (eventStr == undefined || eventStr == "")
    {
      this.addressList[adrIndex] == " ";
      return;
    }*/

    this.addressList[adrIndex] = eventStr;
  }

  /***************************************************************************/

  /** used to avoid loosing focus when inputting mail adresses */
  trackByFunction(index, item) {
    return index;
  }
  
  /***************************************************************************/
   
  /** set searchTerm to project which was selected in the dropdown menu */
  changeSelection(str: string) : void {
    this.storageService.getAssessment(str);
  }

  /***************************************************************************/

  /** 
   * Fill progress bar every 0.02 Seconds 
   * if it is full, navigate to result page 
   */
  startTimer() {
    this.progressInterval = setInterval(() => {
    
      this.progressCounter = this.progressCounter + 1;

      if (this.progressCounter >= 100)
      {
        clearInterval(this.progressInterval);
        //this.isLoading = false;
        return;
      }
    },20)
  }

  /***************************************************************************/

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
  }

}
