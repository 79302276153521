import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AssessmentService } from '../../swagger-typescript/api/assessment.service';
import { RecommendationService } from '../../swagger-typescript/api/recommendation.service';
import { Assessment } from '../../swagger-typescript/model/assessment';
import { Recommendation } from '../../swagger-typescript/model/recommendation';
import { Router, ActivatedRoute } from '@angular/router'
import { ChartDataSets, ChartType, RadialChartOptions, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { TranslationService } from '../../translation/translation.service';
import { FilterTranslationPipe } from '../../translation/filter-translation.pipe'
import { TranslatePipe } from '../../translation/translate.pipe'
import { Subscription } from 'rxjs';
import { StorageService } from '../storage.service';
import { Question, RelevancyAnswer } from 'src/swagger-typescript';

export class EvaluationObject
{
  public categorie: string = "";
  public relevancyAnswerScores: number[] = [];
  public answerScores: number[] = [];
  public recommAnswerScores: number[] = [];
  public recommRelevancyScores: number[] = [];
  
  constructor(cat: string) {
    this.categorie = cat;
  }
}

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  /** used to store the assessment retrieved from the DB */
  private asm: Assessment = null;

  /** used to store all recommendations retrieved from the DB */
  private recomms: Recommendation[] = null;

  /** used to store project name */
  private projectName: string = "";

  /** indicate which question is currently answered */
  private currentQuestion: number = 0;

  /** used to store all infos needed for generating fancy graphs */
  private evaluationObjects: EvaluationObject[] = [];

  /** used to store all distinct question categories */
  private questionEvalCategories : Array<Object> = []; 

  /** used to display progress bar */
  private progressHidden: boolean = false;

  /** used to fill progressbar continiously */
  private progressCounter: number = 0; 

  /** used to set cyclic interval for progressbar */
  public progressInterval;

  /** Subscription for Assessments from the DB */
  private asmSubscription: Subscription;

  /** Subscription for Errors from the DB-conenction */
  private errorSubscription: Subscription;

  /** used to show/hide navigation bar */
  private showStickyNav: boolean = false;

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  @ViewChild('selectElement', {read: ElementRef, static: false}) selectElement: ElementRef;

  /** Text for chart display */
  public currentStateText = this.translatePipe.transform('current state', this.translator.currentLang);
  /** Text for chart display */
  public targetStateText = this.translatePipe.transform('target state', this.translator.currentLang);
  /** Text for chart display */
  public relevanceText = this.translatePipe.transform('relevance', this.translator.currentLang);
  /** Text for chart display */
  public recommendationText = this.translatePipe.transform('recommendation', this.translator.currentLang);

  /** Radar chart options*/
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    responsiveAnimationDuration: 100,
    animation: {
      duration: 100,
      easing: "linear",
      onProgress: function(animation) {
        return new Promise( resolve => setTimeout(resolve, 2000) );
      }
    },
    scale: {
        ticks: {
            min: 0,
            max: 1
        }
    }
  };

  /** radar chart labels */
  public radarChartLabels: Label[] = [];

  /** radar chart data */
  public radarChartData: ChartDataSets[] = [
    { data: [], label: this.currentStateText },
    { data: [], label: this.relevanceText },
    { data: [], label: this.recommendationText }
  ];

  /** radar chart type */
  public radarChartType: ChartType = 'radar';

  /** radar chard colors */
  public radarChartColors:  any[] = [
    { backgroundColor:["rgba(250, 100, 100, 0.5)"] },
    { backgroundColor:["rgba(0, 148, 116, 0.5)" ] },
    { backgroundColor:["rgba(31, 130, 192, 0.5)" ] },
  ];

  /** Scatter chart options */
  public scatterChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: false,
              max: 1,
              min: -1,
              stepSize: 0.5,
              display: 	false
          },       
          display: false
      }],
      xAxes: [{  
        ticks: {
            beginAtZero: true,
            max: 1,
            min: 0,
        },       
    }]
    },
    layout: {
      padding: {
          left: 50,
          right: 50,
          top: 0,
          bottom: 0
      }
    },
  };

  /** Scatter chart data */
  public scatterChartData: ChartDataSets[][] = [
    // {data: [ {x:0.3, y:0} ], label: 'Ist-Zustand', pointRadius: 10},
    // {data: [ {x:0.5, y:0} ], label: 'Relevanz', pointRadius: 10}
  ];

  /** scatter chart colors */
  public scatterChartColors:  any[] = [
    { backgroundColor:["rgba(250, 100, 100, 0.5)"] },
    { backgroundColor:["rgba(0, 148, 116, 0.5)" ] },
    { backgroundColor:["rgba(31, 130, 192, 0.5)" ] }
  ];

  /** Scatter chart type */
  public scatterChartType: ChartType = 'scatter';

  /** constructor */
  constructor(private assessmentService: AssessmentService,
              private router: Router,
              private route: ActivatedRoute,
              private recommendationService: RecommendationService,
              private translator: TranslationService,
              private filterPipe: FilterTranslationPipe,
              private translatePipe: TranslatePipe,
              private storageService: StorageService,
              ) {}
  
  /***************************************************************************/
   
  /**
   * Lifecycle hook - triggered on page initialization
   * Display recommendations depending on the chosen answers
   * for the survey
   */
  ngOnInit() {

    // add scroll event to show sticky nav bar when scrolling down
    window.addEventListener('scroll', this.scrollEvent, true);

    // start timer and hide loading bar when timer is up
    this.startTimer();

    // (+) before params turns the string into a number
    //this.currentQuestion = +this.route.snapshot.paramMap.get('question');
    this.projectName = this.route.snapshot.paramMap.get('project');

    this.subscribeToAssessmentChanges()

    // navigate to login page, if server responds with status 401 to assessment requests
    this.subscribeToAuthorizationErrors()

  }

  /***************************************************************************/

  /**
   * Lifecycle hook - delete all subscriptions to avoid memory leak
   */
  ngOnDestroy() 
  {
    window.removeEventListener('scroll', this.scrollEvent, true);

    this.asmSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }
   
  /***************************************************************************/

  /** 
   * Fill progress bar every 0.02 Seconds 
   * if it is full, navigate to result page 
   */
  startTimer() {
    this.progressInterval = setInterval(() => {
    
      this.progressCounter = this.progressCounter + 1;

      if (this.progressCounter > 100)
      {
        clearInterval(this.progressInterval);
        this.progressHidden = true;
        return;
      }
    },20)
  }

  /***************************************************************************/
   
  /** save assessment in this component, if it cahnges in the storage service */
  subscribeToAssessmentChanges() 
  {
    this.asmSubscription = this.storageService.asmItem$
    .subscribe((data: Assessment) => 
    {
      if (data == false || data == null || data == undefined) 
      {
        //alert('No assessment found for the chosen project name');
        return;
      }
      this.asm = data;

      // fill question category array
      var helperArray: any[] = [];  
      for (let questionCats of this.asm.questions)
      {
        var text = this.filterPipe.transform(questionCats.categories[0].text, this.translator.currentLang);
        helperArray.push(text);
      }

      helperArray = helperArray.filter(this.uniqueFilter); 
      this.evaluationObjects = [];
      for (let cats of helperArray)
      {
        this.evaluationObjects.push(new EvaluationObject(cats));
      }

      // get all recommendations and calculate result graphs
      this.calculateResult();
    });
  }

  /***************************************************************************/

  /**
   * navigate to login page, if server responds with status 401 to assessment requests
   */
  subscribeToAuthorizationErrors()
  {
    this.errorSubscription = this.storageService.errorItem$
    .subscribe((error: any) => 
    {
      if (error == null)
      {
        return;
      }
      else if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });
  }

  /***************************************************************************/
      
  /** 
   * get all recommendation and calculate results for the chosen assessment
   * and update the assessment stored internally 
   */
  calculateResult(): void
  {
    // TODO get all recomms nur für ein assessment (aus der question - recomm zuordnung)
    this.recommendationService.getAllRecommendations().subscribe( recommData => {
      if (recommData == null || recommData == undefined) 
      {
        this.popupText = "No recommendations for the chosen project name";
        this.showPopup = true;
        return;
      }

      this.recomms = recommData;

      // calculate results for the chosen assessment and update assessment storage
      if (this.calculateRecommendations())
      {
        this.assessmentService.getAssessment(this.projectName).subscribe( newData => {
          if (newData == null || newData == undefined) 
          {
            this.popupText = "No assessment found for the chosen project name";
            this.showPopup = true;
            return;
          }
          this.asm = newData;

          // fill category array
          // for (let evalCats of this.asm.evaluatedRecommendations)
          for (let evalCats of this.asm.questions)
          {
            this.questionEvalCategories.push(evalCats.categories[0]);
          }

        });
      }

      
    });
  }

  /***************************************************************************/
      
  /**
   * map together all recommendation for every question of the answered 
   * assessment
   */
  calculateRecommendations() : boolean 
  {
    // fill evalObject for later use in generating fancy graphs
    this.prepareEvaluationObjectForGraphGeneration();
    
    this.calculateGraphResults();
    return true;
  }

  /***************************************************************************/
      
  /** Just for already evaluated Assessments -  */
  prepareEvaluationObjectForGraphGeneration()
  {
    // fill evalObject for later use in generating fancy graphs
    for (let question of this.asm.questions)
    {
      for (let ans of question.answers)
      {
        for (let obj of this.evaluationObjects)
        { 
          var text = this.filterPipe.transform(question.categories[0].text, this.translator.currentLang);      
          if (text == obj.categorie && ans.isSelected == true)
          {
            obj.answerScores.push(+ans.standardScore);
            break;
          }
        }

        for (let obj of this.evaluationObjects)
        { 
          var text = this.filterPipe.transform(question.categories[0].text, this.translator.currentLang);      
          if (text == obj.categorie && ans.isRecommended == true)
          {
            obj.recommAnswerScores.push(+ans.standardScore);
            break;
          }
        }   
      }
      for (let relevance of question.relevancies)
      {
        for (let relAns of relevance.answers)
        {
          for (let obj of this.evaluationObjects)
          {    
            var text = this.filterPipe.transform(question.categories[0].text, this.translator.currentLang);   
            if (text == obj.categorie &&  relAns.isSelected == true)
            {
              obj.relevancyAnswerScores.push(+relAns.value);
            }
          }

          for (let obj of this.evaluationObjects)
          {    
            var text = this.filterPipe.transform(question.categories[0].text, this.translator.currentLang);   
            if (text == obj.categorie &&  relAns.isRecommended == true)
            {
              obj.recommRelevancyScores.push(+relAns.value);
            }
          }
        }
      }
    }
  }

  /***************************************************************************/
      
  /**
   * calculate end points of all graphs based on the answers given in the assessment
   */
  calculateGraphResults() : boolean 
  {
    // fill radarChart labels with the evaluated Categories
    this.radarChartLabels = [];
    
    for (let obj of this.evaluationObjects)
    {
      //console.log(obj);
      var meanAnsScore = 0;
      var meanRelScore = 0;
      var meanRecScore = 0;

      meanAnsScore = (obj.answerScores.reduce((a, b) => a + b, 0)) / (100 * obj.answerScores.length);
      meanRelScore = (obj.relevancyAnswerScores.reduce((a, b) => a + b, 0)) / (100 * obj.relevancyAnswerScores.length);
      meanRecScore = (obj.recommAnswerScores.reduce((a, b) => a + b, 0)) / (100 * obj.recommAnswerScores.length);

      if (isNaN(meanRecScore)) 
      {
        meanRecScore = 0;
      }

      this.radarChartLabels.push(obj.categorie);

      this.radarChartData[0].data.push(meanAnsScore);
      this.radarChartData[1].data.push(meanRelScore);
      this.radarChartData[2].data.push(meanRecScore);

      this.scatterChartData.push([]);
      this.scatterChartData[this.scatterChartData.length - 1]
        .push( {data: [ {x: meanAnsScore, y: 0} ], label: this.currentStateText, pointRadius: 10} );
      this.scatterChartData[this.scatterChartData.length - 1]
        .push( {data: [ {x: meanRelScore, y: 0} ], label: this.relevanceText, pointRadius: 10} );

      if (meanRecScore == 0)
      {
        this.scatterChartData[this.scatterChartData.length - 1]
          .push( {data: [ {x: -1, y: 0} ], label: this.recommendationText, pointRadius: 10} );
      }
      else
      {
        this.scatterChartData[this.scatterChartData.length - 1]
          .push( {data: [ {x: meanRecScore, y: 0} ], label: this.recommendationText, pointRadius: 10} );
      }  
    }

    return true;
  }

  /***************************************************************************/

  /** 
   * change color of the "save button" when the recommendation text is changed 
   */
  textChange(event: Event) : void 
  {
    const hasClass = (<HTMLInputElement>event.target).nextElementSibling.classList
      .contains('btn-secondary');

    if (hasClass)
    {
      (<HTMLInputElement>event.target).nextElementSibling.classList.remove('btn-secondary');
      (<HTMLInputElement>event.target).nextElementSibling.classList.add('btn-primary');
      (<HTMLInputElement>event.target).nextElementSibling.children[0].classList.remove('isVisible');
      (<HTMLInputElement>event.target).nextElementSibling.children[0].classList.add('isNotVisible');
      (<HTMLInputElement>event.target).nextElementSibling.children[1].classList.remove('isNotVisible');
      (<HTMLInputElement>event.target).nextElementSibling.children[1].classList.add('isVisible');
    }
  }

  /***************************************************************************/
    
  /**
   * save changes to the recommendations of the assessment
   */
  saveRecommChanges(event: Event, recomm: Recommendation) : any
  {
    const hasClass = (<HTMLInputElement>event.target).classList.contains('btn-primary');
    const parentHasClass = (<HTMLInputElement>event.target).parentElement.classList.contains('btn-primary');

    if (hasClass)
    {
      this.recommendationService.updateEvaluatedRecommendation(recomm, this.asm._id, recomm._id).subscribe(data => {
        
        if (data['message'] != 'OK')
        {
          this.popupText = "Changes could not be saved";
        this.showPopup = true;
          return false;
        }
        (<HTMLInputElement>event.target).classList.remove('btn-primary');
        (<HTMLInputElement>event.target).classList.add('btn-secondary');       
        (<HTMLInputElement>event.target).children[0].classList.remove('isNotVisible');
        (<HTMLInputElement>event.target).children[0].classList.add('isVisible');
        (<HTMLInputElement>event.target).children[1].classList.remove('isVisible');
        (<HTMLInputElement>event.target).children[1].classList.add('isNotVisible');
        return true;
      });
    }
    else if (parentHasClass)
    {
      this.recommendationService.updateEvaluatedRecommendation(recomm, this.asm._id, recomm._id).subscribe(data => {
        
        if (data['message'] != 'OK')
        {
          this.popupText = "Changes could not be saved";
        this.showPopup = true;
          return false;
        }
        (<HTMLInputElement>event.target).parentElement.classList.remove('btn-primary');
        (<HTMLInputElement>event.target).parentElement.classList.add('btn-secondary');       
        (<HTMLInputElement>event.target).parentElement.children[0].classList.remove('isNotVisible');
        (<HTMLInputElement>event.target).parentElement.children[0].classList.add('isVisible');
        (<HTMLInputElement>event.target).parentElement.children[1].classList.remove('isVisible');
        (<HTMLInputElement>event.target).parentElement.children[1].classList.add('isNotVisible');
        return true;
      });
    }
  }

  /***************************************************************************/
    
  /**
   * Used to filter out multible entries in an array 
   * only unique ones remain in the array
   * @param value current value of the array
   * @param index current index
   * @param self input array (?)
   */
  uniqueFilter(value, index, self) { 
    return self.indexOf(value) === index;
  }

  /***************************************************************************/
    
  /**
   * Used to filter out all recommendations that are not part of the input category
   * @param cat Category name
   */
  filterRecommendationsOfCategory(cat){
    return this.asm.evaluatedRecommendations
      .filter(data => this.filterPipe
        .transform(data.categories[0].text, this.translator.currentLang) == cat);
  }

  /***************************************************************************/
    
  /**
   * Used to filter out all recommendations that are not part of the input category
   * @param cat Category name
   */
  filterQuestionOfCategory(cat){
    return this.asm.questions
      .filter(data => this.filterPipe
        .transform(data.categories[0].text, this.translator.currentLang) == cat);
  }

  /***************************************************************************/
    
  /**
   * Used to return a list of the input category array in the current language
   * @param categories Array of Category Objects
   */
  getCurrentLangList(categories: Array<Object>){
    if (categories == undefined)
    {
      return [];
    }

    var helperArray = [];

    for (let cat of categories)
    {
      helperArray.push(this.filterPipe.transform(cat['text'], this.translator.currentLang));
    }
    
    helperArray = helperArray.filter(this.uniqueFilter);
    return helperArray;
  }

  /***************************************************************************/
    
  /**
   * Used to return a list of the input category array in the current language
   * @param categories Array of Category Objects
   */
  getCurrentLangListForNavigation(categories: Array<Object>){
    if (categories == undefined)
    {
      return [];
    }
    
    var helperArray = [];
    
    for (let cat of categories)
    {
      var matchFound = false;
      for (let helperEntry of helperArray)
      {
        if (helperEntry['name'] == cat['name'])
        {
          matchFound = true;
          break;
        }
      }
      if (matchFound == false)
      {
        helperArray.push(cat);
      }
      matchFound = false;

      //helperArray.push(this.filterPipe.transform(cat['text'], this.translator.currentLang));
    }

    //helperArray = helperArray.filter(this.uniqueFilter);
    return helperArray;
  }

  /***************************************************************************/

  /**
   * Recommend multiple answers to the questioin
   */
  recommendAnswer(qID: string, aID: string) 
  {
    for (var i = 0; i < this.asm.questions.length; i++)
    {
      if (this.asm.questions[i]._id == qID)
      {
        for (var k = 0; k < this.asm.questions[i].answers.length; k++)
        {
          if (this.asm.questions[i].answers[k]._id == aID)
          {
            if (this.asm.questions[i].answers[k].isRecommended == true)
            {
              this.asm.questions[i].answers[k].isRecommended = false;
            }
            else 
            {
              this.asm.questions[i].answers[k].isRecommended = true;
            }
            return;
          }    
        }
      }
    }
  }
  

  /***************************************************************************/

  /**
   * Recommend ONE answer to the relevancy
   */
  recommendRelevancy(qID: string, aID: string) 
  {
    for (var i = 0; i < this.asm.questions.length; i++)
    {
      if (this.asm.questions[i]._id == qID)
      {
        for (var k = 0; k < this.asm.questions[i].relevancies[0].answers.length; k++)
        {
          if (this.asm.questions[i].relevancies[0].answers[k]._id == aID)
          {
            if (this.asm.questions[i].relevancies[0].answers[k].isRecommended == true)
            {
              this.asm.questions[i].relevancies[0].answers[k].isRecommended = false;
            }
            else 
            {
              this.asm.questions[i].relevancies[0].answers[k].isRecommended = true;
            }
          }
          else    
          {
            this.asm.questions[i].relevancies[0].answers[k].isRecommended = false;
          }  
        }
        return;
      }
    }
  }
  
  /***************************************************************************/

  /** Event to show navbar when a specific scroll distance is reached */
  scrollEvent = (event: any): void => {
    const scrollNumber = event.srcElement.scrollTop;
    if (this.showStickyNav != true && event.srcElement.classList.contains('specificBody') == true && scrollNumber >= 100 )
    {
      this.showStickyNav = true;
    }
    else if (this.showStickyNav != false && event.srcElement.classList.contains('specificBody') == true && scrollNumber < 100 )
    {
      this.showStickyNav = false;
    }
  }

  /***************************************************************************/
    
  /** navigate to the question category which was selected */
  linkToCategory(str: any) {

    // navigate to first question of the category 
   
    for (var i = 0; i < this.asm.questions.length; i++)
    {
      var text = this.filterPipe.transform(this.asm.questions[i].categories[0].text, this.translator.currentLang);
      if (text == str)
      {
        document.getElementById(this.asm.questions[i].categories[0].name).scrollIntoView();
        this.selectElement.nativeElement.children[0].selected = true;
        break;
      }
    }
  }

  /***************************************************************************/
    
  /** navigatee back to the top of the page */
  linkToTop() {
    document.getElementById('top').scrollIntoView();
  }

  /***************************************************************************/
    
  /** calculate recommendation graph */
  showRecommendation(str: any) {

    // reset scores
    for (let obj of this.evaluationObjects)
    { 
      obj.recommAnswerScores = [];
    }

    // fill evalObject for later use in generating fancy graphs
    for (let question of this.asm.questions)
    {
      for (let ans of question.answers)
      {
        for (let obj of this.evaluationObjects)
        { 
          var text = this.filterPipe.transform(question.categories[0].text, this.translator.currentLang);      
          if (text == obj.categorie && ans.isRecommended == true)
          {
            obj.recommAnswerScores.push(+ans.standardScore);
            break;
          }
        }   
      }
      for (let relevance of question.relevancies)
      {
        for (let relAns of relevance.answers)
        {
          for (let obj of this.evaluationObjects)
          {    
            var text = this.filterPipe.transform(question.categories[0].text, this.translator.currentLang);   
            if (text == obj.categorie &&  relAns.isRecommended == true)
            {
              obj.recommRelevancyScores.push(+relAns.value);
            }
          }
        }
      }
    }
    
    this.radarChartData[2].data = [];
    for (let obj of this.evaluationObjects)
    {
      //console.log(obj);
      var meanRecScore = 0;

      meanRecScore = (obj.recommAnswerScores.reduce((a, b) => a + b, 0)) / (100 * obj.recommAnswerScores.length);

      if (isNaN(meanRecScore)) 
      {
        meanRecScore = 0;
      }

      this.radarChartData[2].data.push(meanRecScore);

      this.scatterChartData[this.evaluationObjects.indexOf(obj)][2].data = [ {x: meanRecScore, y: 0} ];
    }

    // save recommendation changes in DB and scroll back to the top
    this.assessmentService.updateAllAssessmentQuestions(this.asm, this.asm._id)
      .subscribe((data: Assessment) => 
      {
        console.log(data)
        if (data['message'] != "OK") 
        {
          this.popupText = "Recomendations could not be saved";
          this.showPopup = true;
        }
      });
    document.getElementById('top').scrollIntoView();
    return true;
  }

  /***************************************************************************/
    
  /**  */
  getCommentLanguageIndex(question: Question) {
    var lang = this.translator.currentLang;
    for (let comment of question.comment)
    {
      if (comment.lang == lang)
      {
        return question.comment.indexOf(comment);
      }
    }

    return 0;
  }

  /***************************************************************************/
    
  /** */
  getRelevancyCommentLanguageIndex(answer: RelevancyAnswer) {
    var lang = this.translator.currentLang;
    for (let comment of answer.comment)
    {
      if (comment.lang == lang)
      {
        return answer.comment.indexOf(comment);
      }
    }

    return 0;
  }
}
