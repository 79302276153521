import { Component, OnInit, HostListener  } from '@angular/core';
import { Assessment, Categorie, Question, Answer, RelevancyAnswer, Recommendation, IdHandlerService, AssessmentService } from 'src/swagger-typescript';
import { TranslationService } from 'src/translation/translation.service';
import { StorageService } from '../storage.service';
import { Router } from '@angular/router';
import { ProjectsService } from '../../swagger-typescript/api/projects.service';
import { Project } from '../../swagger-typescript/model/project';
import { Subscription } from 'rxjs';

export interface logicRelation { 
  /** answer _id */
  answer: string;
  /** relevancy _id */
  relevancy: string;
}

/***************************************************************************/

export interface recommendationLogic { 
  _id?: string;
  /** recommendation _id */
  recommendation?: string;
  /** array of relations which relate to the recommendation */
  relation?: logicRelation[];
}

/***************************************************************************/

/** Used to create deep copy of array */
export const deepCopy = <T>(target: T): T => {
  if (target === null) 
  {
    return target;
  }

  if (target instanceof Date)
  {
    return new Date(target.getTime()) as any;
  }

  if (target instanceof Array) 
  {
    const theCopy = [] as any[];
    (target as any[]).forEach((value) => { theCopy.push(value); });
    return theCopy.map((n: any) => deepCopy<any>(n)) as any;
  }

  if (typeof target === 'object' && target !== {}) 
  {
    const theCopy = { ...(target as { [key: string]: any }) } as { [key: string]: any };

    Object.keys(theCopy).forEach(q => {
      /*if (q == "_id")
      {
        theCopy[q] = "555";
      }*/
      theCopy[q] = deepCopy<any>(theCopy[q]);
    });
    return theCopy as T;
  }

  return target;
};

/***************************************************************************/

@Component({
  selector: 'app-create-assessment',
  templateUrl: './create-assessment.component.html',
  styleUrls: ['./create-assessment.component.css']
})
export class CreateAssessmentComponent implements OnInit {

  /** store created Assesment in it */
  private asm: Assessment = null;

  /** store created Assesment in it */
  private title: string = "";

  /** store categorie list */
  private categoryArray: Categorie[] = [];
  
  /** store sub categorie map */
  private subCategoryMap: Map<string, Categorie[]> = new Map<string, Categorie[]>();

  /** store sub categorie list */
  private recommLogicArray: recommendationLogic[] = [];

  /** used to show/hide navigation bar */
  private showStickyNav = false;

  /** used to display progress bar */
  private progressHidden = true;

  /** temorarly store ID which should be copied */
  private copiedId: string = "";

  /** Subscription for Assessments from the DB */
  private asmSubscription: Subscription;

  /** Subscription for Errors from the DB-conenction */
  private errorSubscription: Subscription;

  /** stores all assessment projects in an array */
  private projectList : Project[] = [];

  private isStandard: boolean = false;

  public hiddenCategories: string[] = [];
  public hiddenSubCategories: Map<string, string[]> = new Map<string, string[]>();
  public hiddenQuestions: number[] = [];

  public highlightAnswer: number = 0;
  public highlightRelevancy: number = 0;

  /** used to fill progressbar continiously */
  private progressCounter: number = 0; 

  /** used to set cyclic interval for progressbar */
  public progressInterval;

  /** indicate theat data is beeing retrieved from the DB */
  private isLoading : boolean = false;

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  constructor(
    private translator: TranslationService,
    private storageService: StorageService,
    private idHandlerService: IdHandlerService,
    private assessmentService: AssessmentService,
    private projectsService: ProjectsService,
    private router: Router,
  ) {}

  /**********************************************************************************************/

  /** 
   * Lifecycle hook - triggered on page init
   * get new ID from server and create emty assessment
   */
  ngOnInit() {
    this.title = "newAssessment_" + this.storageService.currentUser;

    this.storageService.resetAssessment(); 

    this.idHandlerService.getUniqueId().subscribe( data => {
      if (data != null && data['id'] != "")
      {
        var newId = (data['id']);

        this.asm = {
          _id: newId,
          projects: [],
          contact: [],
          questions: [],
          assessmentTopic: [],
          assessmentType: [],
          searchTags: [],
          viewRights: [],
          editRights: [],
          creatorId: this.storageService.currentUser,
          creationDate: new Date(),
          evaluationDate: new Date(0),
          isEvaluated: false,
          evaluatedRecommendations: [],
          evaluationCriterias: [],
          evaluationTypes: [],
          introduction: {name: "introduction", text: [ {lang: "de", content:"-"}, {lang: "en", content:"-"} ] }
        };

        this.asm.editRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
        this.asm.viewRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
      }
    },
    error => {
      this.popupText = "Something went wrong - Code: " + error.status;
      this.showPopup = true;
    });

    /*this.asm = {
      _id: "",
      projects: [],
      contact: [],
      questions: [],
      assessmentTopic: [],
      assessmentType: [],
      searchTags: [],
      viewRights: [],
      editRights: [],
      creatorId: "",
      creationDate: new Date(),
      evaluationDate: new Date(0),
      isEvaluated: false,
      evaluatedRecommendations: [],
      evaluationCriterias: [],
      evaluationTypes: [],
      introduction: {name: "introduction", text: [ {lang: "de", content:"-"}, {lang: "en", content:"-"} ] }
    };

    this.asm.editRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
    this.asm.viewRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });*/

    this.projectsService.getAllProjects().subscribe( data => {
      for (let entry of data)
      {
        this.projectList.push(entry);
        //this.loadingSuccess = true;
      }
    },
    // redirect to login page, if user is not logged in yet
    error => {
      if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });

    // load assessment data
    this.asmSubscription = this.storageService.asmItem$
    .subscribe((data: any) => 
    {
      // no assessment found for that project name
      if (data == false || data == null || data == undefined) 
      {
        return;
      }

      this.loadAssessment();
    });

    // navigate to login page, if server responds with status 401 to assessment request
    this.errorSubscription = this.storageService.errorItem$
    .subscribe((error: any) => 
    {
      if (error == null)
      {
        return;
      }
      else if(error.status == 401)
      {
        //this.isLoading = false;
        this.router.navigate(['/login', 'login']);
      }
      //this.isLoading = false;
    });
  }

  /******************************************************************************/

  ngOnDestroy()
  {
    this.errorSubscription.unsubscribe();
    this.asmSubscription.unsubscribe();
  }

  /**********************************************************************************************/

  /** add new category to categoryArray */
  addCategory(event: Event)
  {
    var tempText = [{_id: "", 
                    lang: this.translator.currentLang, 
                    content: ("newCat_" + this.categoryArray.length + 1)}];
    
    for (let language of this.translator.langList)
    {
      if (language != this.translator.currentLang) {
        tempText.push({_id: "", 
                      lang: language, 
                      content: "-"});
      }
    }

    this.categoryArray.push({_id: "", 
                             name:("newCat_" + this.categoryArray.length + 1), 
                             text: tempText});
    
  }

  /**********************************************************************************************/

  /** add new sub category to the subCategoryMap */
  addSubCategory(currentCat:string)
  {
    var tempText = [{_id: "", 
                    lang: this.translator.currentLang, 
                    content: (currentCat)}];
    
    for (let language of this.translator.langList)
    {
      if (language != this.translator.currentLang) {
        tempText.push({_id: "", 
                      lang: language, 
                      content: "-"});
      }
    }

    if (!this.subCategoryMap.has(currentCat))
    {
      this.subCategoryMap.set(currentCat, []);
    }

    var helperArray = this.subCategoryMap.get(currentCat);
    helperArray.push({_id: "", 
                      name:(currentCat), 
                      text: tempText});

    this.subCategoryMap.set(currentCat, helperArray);
  }

  /**********************************************************************************************/

  /** Get a new ID from the server and add a question to the assessment */
  addQuestion(currentCat: string, currentSubCat: string)
  {
    this.idHandlerService.getUniqueId().subscribe( data => {
      if (data != null && data['id'] != "")
      {
        var newId = (data['id']);

        if (this.asm.questions.length <= 0)
        {
          var newQuestion: Question = {
            _id: newId,
            text: [{lang: "de", content:"-"}, {lang: "en", content:"-"}],
            relevancies: [{_id:"", name:"standardRelevancy", text: [{_id:"-", lang:"en", content:"Relevancy"},{_id:"-", lang:"de", content:"Relevanz"}], answers: []}],
            categories: [],
            subCategories: [],
            searchTags: [],
            viewRights: [],
            editRights: [],
            creatorId: "",
            creationDate: new Date(),
            questionType: [],
            benefitCategories: [],
            answers: [],
            evaluationLogic: { name: "comparision", 
                              text: [ {lang: "de", content:"Vergleich"}, {lang: "en", content:"Comparison"}],
                              logic:  []},
            expertiseContact: [],
            mediaLinks: [],
            comment: [{lang: "de", content:""}, {lang: "en", content:""}],
            recommendations: [],
            meanTimeForAnswering: 0,
            stoppedTimeForAnswering: 0
          };
        }
        else 
        {
          var newQuestion: Question = {
            _id: newId,
            text: [{lang: "de", content:"-"}, {lang: "en", content:"-"}],
            relevancies: deepCopy<any>(this.asm.questions[this.asm.questions.length - 1].relevancies),
            categories: [],
            subCategories: [],
            searchTags: deepCopy<any>(this.asm.questions[this.asm.questions.length - 1].searchTags),
            viewRights: [],
            editRights: [],
            creatorId: "",
            creationDate: new Date(),
            questionType: [],
            benefitCategories: deepCopy<any>(this.asm.questions[this.asm.questions.length - 1].benefitCategories),
            answers: deepCopy<any>(this.asm.questions[this.asm.questions.length - 1].answers),
            evaluationLogic: { name: "comparision", 
                              text: [ {lang: "de", content:"Vergleich"}, {lang: "en", content:"Comparison"}],
                              logic:  []},
            expertiseContact: deepCopy<any>(this.asm.questions[this.asm.questions.length - 1].expertiseContact),
            mediaLinks: [],
            comment: [{lang: "de", content:""}, {lang: "en", content:""}],
            recommendations: deepCopy<any>(this.asm.questions[this.asm.questions.length - 1].recommendations),
            meanTimeForAnswering: 0,
            stoppedTimeForAnswering: 0
          };

          // get new IDs for answers Relavancy answers and rcommendations
          for (let answer of newQuestion.answers)
          {
            this.idHandlerService.getUniqueId().subscribe( data => {
              if (data != null && data['id'] != "")
              {
                answer._id = data['id'];
                answer.categories = [];
                answer.subCategories = [];
                answer.categories.push({_id: "", name:currentCat, text:[{_id:"", lang:this.translator.currentLang, content:currentCat}]});
                answer.subCategories.push({_id: "", name:currentSubCat, text:[{_id:"", lang:this.translator.currentLang, content:currentSubCat}]});
              }
              else 
              {
                this.popupText = "Something went wrong";
                this.showPopup = true;
              }
            },
            error => {
              this.popupText = "Something went wrong - Code: " + error.status;
              this.showPopup = true;
            });
          }

          for (let relAnswer of newQuestion.relevancies[0].answers)
          {
            this.idHandlerService.getUniqueId().subscribe( data => {
              if (data != null && data['id'] != "")
              {
                relAnswer._id = data['id'];
              }
              else 
              {
                this.popupText = "Something went wrong";
                this.showPopup = true;
              }
            },
            error => {
              this.popupText = "Something went wrong - Code: " + error.status;
              this.showPopup = true;
            });
          }

          for (let recomm of newQuestion.recommendations)
          {
            this.idHandlerService.getUniqueId().subscribe( data => {
              if (data != null && data['id'] != "")
              {
                recomm._id = data['id'];
                recomm.categories = [];
                recomm.subCategories = [];
                recomm.categories.push({_id: "", name:currentCat, text:[{_id:"", lang:this.translator.currentLang, content:currentCat}]});
                recomm.subCategories.push({_id: "", name:currentSubCat, text:[{_id:"", lang:this.translator.currentLang, content:currentSubCat}]});
              }
              else 
              {
                this.popupText = "Something went wrong";
                this.showPopup = true;
              }
            },
            error => {
              this.popupText = "Something went wrong - Code: " + error.status;
              this.showPopup = true;
            });

            // set new source question
            recomm.sourceQuestion = newId;
          }
        }
    
        newQuestion.categories.push({_id: "", name:currentCat, text:[{_id:"", lang:this.translator.currentLang, content:currentCat}]});
        newQuestion.subCategories.push({_id: "", name:currentSubCat, text:[{_id:"", lang:this.translator.currentLang, content:currentSubCat}]});
        newQuestion.questionType.push({name: "textQuestion", text: [ {lang: "de", content:"Textfrage"}, {lang: "en", content:"text question"} ] });
        // newQuestion.editRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
        // newQuestion.viewRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
        
        this.asm.questions.push(newQuestion);
      }
      else 
      {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    },
    error => {
      this.popupText = "Something went wrong - Code: " + error.status;
      this.showPopup = true;
    });

    /*var newQuestion: Question = {
      _id: "newId",
      text: [{lang: "de", content:"-"}, {lang: "en", content:"-"}],
      relevancies: [{_id:"", name:"standardRelevancy", text: [{_id:"", lang:this.translator.currentLang, content:""}], answers: []}],
      categories: [],
      subCategories: [],
      searchTags: [],
      viewRights: [],
      editRights: [],
      creatorId: "",
      creationDate: new Date(),
      questionType: [],
      benefitCategories: [],
      answers: [],
      evaluationLogic: { name: "comparision", 
                         text: [ {lang: "de", content:"Vergleich"}, {lang: "en", content:"Comparison"}],
                         logic:  []},
      expertiseContact: [],
      mediaLinks: [],
      comment: [],
      recommendations: [],
      meanTimeForAnswering: 0,
      stoppedTimeForAnswering: 0
    };

    newQuestion.categories.push({_id: "", name:currentCat, text:[{_id:"", lang:this.translator.currentLang, content:currentCat}]});
    newQuestion.subCategories.push({_id: "", name:currentSubCat, text:[{_id:"", lang:this.translator.currentLang, content:currentSubCat}]});
    newQuestion.questionType.push({name: "textQuestion", text: [ {lang: "de", content:"Textfrage"}, {lang: "en", content:"text question"} ] });
    newQuestion.editRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
    newQuestion.viewRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
    
    this.asm.questions.push(newQuestion);*/

  }

  /**********************************************************************************************/

  /** Get a new ID from the server and add an answer to the question with index qIndex */
  addAnswer(qIndex: number)
  {
    this.idHandlerService.getUniqueId().subscribe( data => {
      if (data != null && data['id'] != "")
      {
        var newId = (data['id']);

        var newAnswer: Answer = {
          _id: newId,
          text: [{lang: "de", content:"-"}, {lang: "en", content:"-"}],
          categories: this.asm.questions[qIndex].categories,
          subCategories: this.asm.questions[qIndex].subCategories,
          searchTags: [],
          viewRights: [],
          editRights: [],
          creatorId: "",
          creationDate: new Date(),
          answerType: [],
          comment: [{lang: "de", content:""}, {lang: "en", content:""}],
          standardScore: "0",
          isSelected: false,
          isRecommended: false,
          mediaLinks: []
    
        };
    
        newAnswer.answerType.push({name: "textAnswer", text: [ {lang: "de", content:"Textantwort"}, {lang: "en", content:"text answer"} ] });
        newAnswer.editRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
        newAnswer.viewRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
        
        this.asm.questions[qIndex].answers.push(newAnswer);
      }
      else 
      {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    },
    error => {
      this.popupText = "Something went wrong - Code: " + error.status;
      this.showPopup = true;
    });

    /*var newAnswer: Answer = {
      _id: "newId",
      text: [{lang: "de", content:"-"}, {lang: "en", content:"-"}],
      categories: this.asm.questions[qIndex].categories,
      subCategories: this.asm.questions[qIndex].subCategories,
      searchTags: [],
      viewRights: [],
      editRights: [],
      creatorId: "",
      creationDate: new Date(),
      answerType: [],
      comment: [],
      standardScore: "3",
      isSelected: false,
      isRecommended: false,
      mediaLinks: []

    };

    newAnswer.answerType.push({name: "textAnswer", text: [ {lang: "de", content:"Textantwort"}, {lang: "en", content:"text answer"} ] });
    newAnswer.editRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
    newAnswer.viewRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
    
    this.asm.questions[qIndex].answers.push(newAnswer);*/

  }

  /**********************************************************************************************/

  /** Get a new ID from the server and add a relevancy answer to the question with index qIndex */
  addRelAnswer(qIndex: number)
  {
    this.idHandlerService.getUniqueId().subscribe( data => {
      if (data != null && data['id'] != "")
      {
        var newId = (data['id']);

        var newAnswer: RelevancyAnswer = {
          _id: newId,
          text: [{lang: "de", content:"-"}, {lang: "en", content:"-"}],
          value: "0",
          isSelected: false,
          isRecommended: false,
          comment: [{lang: "de", content:""}, {lang: "en", content:""}]
        };
        
        this.asm.questions[qIndex].relevancies[0].answers.push(newAnswer);
      }
      else 
      {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    },
    error => {
      this.popupText = "Something went wrong - Code: " + error.status;
      this.showPopup = true;
    });

    /*var newAnswer: RelevancyAnswer = {
      _id: "newId",
      text: [{lang: "de", content:"-"}, {lang: "en", content:"-"}],
      value: "6",
      isSelected: false,
      isRecommended: false,
    };
    
    this.asm.questions[qIndex].relevancies[0].answers.push(newAnswer);*/

  }

  /**********************************************************************************************/

  /** Get a new ID from the server and add a recommendation to the question with index qIndex */
  addRecommendation(qIndex: number)
  {
    this.idHandlerService.getUniqueId().subscribe( data => {
      if (data != null && data['id'] != "")
      {
        var newId = (data['id']);

        var newRecomm: Recommendation = {
          _id: newId,
          text: [{lang: "de", content:"-"}, {lang: "en", content:"-"}],
          isSelected: false,
          categories: this.asm.questions[qIndex].categories,
          subCategories: this.asm.questions[qIndex].subCategories,
          searchTags: [],
          viewRights: [],
          editRights: [],
          creatorId: "",
          creationDate: new Date(),
          sourceQuestion: this.asm.questions[qIndex]._id
        };

        this.asm.questions[qIndex].recommendations.push(newRecomm);
      }
      else 
      {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    },
    error => {
      this.popupText = "Something went wrong - Code: " + error.status;
      this.showPopup = true;
    });

    /*var newRecomm: Recommendation = {
      _id: "newId",
      text: [{lang: "de", content:"-"}, {lang: "en", content:"-"}],
      isSelected: false,
      categories: this.asm.questions[qIndex].categories,
      subCategories: this.asm.questions[qIndex].subCategories,
      searchTags: [],
      viewRights: [],
      editRights: [],
      creatorId: "",
      creationDate: new Date(),
      sourceQuestion: this.asm.questions[qIndex]._id
    };

    this.asm.questions[qIndex].evaluationLogic.logic.push({ recommendation: "newId", 
                                                            relation: [ {answer: "", relevancy: ""} ] });
    this.asm.questions[qIndex].recommendations.push(newRecomm);*/

    /*"evaluationLogic": { "name": "comparision", "text": [ {"lang": "de", "content":"Vergleich"}, {"lang": "en", "content":"Comparison"}],
                         "logic":  {"recommendation": "XXX", 
                           "relation": [ {"answer": "XXX", "relevancy": "XXX"}, 
                                         {"answer": "XXX", "relevancy": "XXX"} ] } },*/
  }

  /**********************************************************************************************/

  /** Add a relation (which answer and relevancy has to be selected, that a recommendation is shown) to the question with index qIndex */
  addRelation(questionIndex: number, recommIndex: number)
  {
    this.asm.questions[questionIndex].evaluationLogic.logic.push(
      { recommendation: this.asm.questions[questionIndex].recommendations[recommIndex]._id,
        relation: [ {answer: "", relevancy: ""} ] }
    )

  }

  /**********************************************************************************************/

  /** copy the Id of an answer - should be triggered, when clicking on the Div element of the answer */
  copyAnswerId(questionIndex: number, answerIndex: number)
  {
    console.log("123");
    console.log(this.asm);
    console.log(questionIndex);
    console.log(answerIndex);
    this.copiedId = this.asm.questions[questionIndex].answers[answerIndex]._id;
    console.log(this.asm.questions[questionIndex].answers[answerIndex]._id);
  }

  /**********************************************************************************************/

  /** copy the Id of an relevancy answer - should be triggered, when clicking on the Div element of the answer */
  copyRelevancyId(questionIndex: number, relAnswerIndex: number)
  {
    this.copiedId = this.asm.questions[questionIndex].relevancies[0].answers[relAnswerIndex]._id;
    console.log(this.copiedId);
  }

  /**********************************************************************************************/

  /** paste ID into the target element */
  pasteIdAnswer(event: Event, qIndex: number, lIndex: number, rIndex: number)
  {
    if ((<HTMLInputElement>event.currentTarget).value != "")
    {
      return;
    }

    (<HTMLInputElement>event.currentTarget).value = this.copiedId;
    this.asm.questions[qIndex].evaluationLogic.logic[lIndex].relation[rIndex].answer = this.copiedId;
    this.copiedId = "";
  }

  /**********************************************************************************************/

  /** paste ID into the target element */
  pasteIdRelevancy(event: Event, qIndex: number, lIndex: number, rIndex: number)
  {
    if ((<HTMLInputElement>event.currentTarget).value != "")
    {
      return;
    }

    (<HTMLInputElement>event.currentTarget).value = this.copiedId;
    this.asm.questions[qIndex].evaluationLogic.logic[lIndex].relation[rIndex].relevancy = this.copiedId;
    this.copiedId = "";
  }

  /**********************************************************************************************/

  /** Highlight answer which fits to the ID of the focused input field */
  showRelatedAnswer(questionIndex: number, event: Event)
  {
    if ((<HTMLInputElement>event.currentTarget).value == "")
    {
      return;
    }

    for (var i = 0; i < this.asm.questions[questionIndex].answers.length; i++)
    {
      
      if ((<HTMLInputElement>event.currentTarget).value == this.asm.questions[questionIndex].answers[i]._id)
      {
        this.highlightAnswer = (questionIndex+1) * 10000 + (i+1);
        return;
      }
    }
  }

  /**********************************************************************************************/

  /** Remove highlight of answer which fits to the ID of the focused input field */
  hideRelatedAnswer()
  {
    this.highlightAnswer = 0;
  }

  /**********************************************************************************************/

  /** Highlight relevancy answer which fits to the ID of the focused input field */
  showRelatedRelevancy(questionIndex: number, event: Event)
  {
    if ((<HTMLInputElement>event.currentTarget).value == "")
    {
      return;
    }
    
    for (var i = 0; i < this.asm.questions[questionIndex].relevancies[0].answers.length; i++)
    {
      if ((<HTMLInputElement>event.currentTarget).value == this.asm.questions[questionIndex].relevancies[0].answers[i]._id)
      {
        this.highlightRelevancy = (questionIndex+1) * 10000 + (i+1);
        return;
      }
    }
  }

  /**********************************************************************************************/

  /** Remove highlight of relevancy answer which fits to the ID of the focused input field */
  hideRelatedRelevancy()
  {
    this.highlightRelevancy = 0;
  }

  

  /**********************************************************************************************/

  /** toggle visibility of category Div */
  toggleCategoryVisibility(catName: string)
  {
    for(let name of this.hiddenCategories){
      if (name == catName)
      {
        this.hiddenCategories.splice(this.hiddenCategories.indexOf(name), 1);
        return;
      }
    }

    this.hiddenCategories.push(catName);  
  }

  /**********************************************************************************************/

  /** toggle visibility of sub category Div */
  toggleSubCategoryVisibility(catName: string, subCatName: string)
  {
    for(let tuple of this.hiddenSubCategories){
      if (tuple[0] == catName)
      {
        for(let entries of this.hiddenSubCategories.get(tuple[0])){
          if(entries == subCatName)
          {
            this.hiddenSubCategories.get(tuple[0]).splice(this.hiddenSubCategories.get(tuple[0]).indexOf(entries), 1);
            return;
          }
        }
        this.hiddenSubCategories.get(tuple[0]).push(subCatName);
        return;
      }
    }

    this.hiddenSubCategories.set(catName, [subCatName]);  
  }

  /**********************************************************************************************/

  /** toggle visibility of question Div */
  toggleQuestionVisibility(questionIndex: number)
  {
    for(let index of this.hiddenQuestions){
      if (index == questionIndex)
      {
        this.hiddenQuestions.splice(this.hiddenQuestions.indexOf(index), 1);
        return;
      }
    }

    this.hiddenQuestions.push(questionIndex);  
  }

  /**********************************************************************************************/

  /** Delete category from categoryArray, all entries in subCategroyMap and all questions from asm with the category */
  deleteCat(catName: string)
  {
    // delete qestion with cat
    /*for(let question of this.asm.questions){
      if (question.categories[0].name == catName)
      {
        this.asm.questions.splice(this.asm.questions.indexOf(question), 1);
      }
    }  */

    var i = 0;
    while (i < this.asm.questions.length)
    {
      if (this.asm.questions[i].categories[0].name == catName)
      {
        this.asm.questions.splice(this.asm.questions.indexOf(this.asm.questions[i]), 1);
      }
      else 
      {
        i++;
      }
    }

    // delete cat in subcat map
    this.subCategoryMap.forEach((value: Categorie[], key: string) => {
      if (key == catName)
      {
        console.log(this.subCategoryMap);
        this.subCategoryMap.delete(key);
      }
    });

    // delete cat in cat array
    /*for (let cat of this.categoryArray)
    {
      if (cat.name == catName)
      {
        this.categoryArray.splice(this.categoryArray.indexOf(cat), 1);
      }
    }*/

    var k = 0;
    while (k < this.categoryArray.length)
    {
      if (this.categoryArray[k].name == catName)
      {
        this.categoryArray.splice(this.categoryArray.indexOf(this.categoryArray[k]), 1);
      }
      else 
      {
        k++;
      }
    }

    // TODO rausnehmen
    console.log(this.subCategoryMap);
  }

  /**********************************************************************************************/

  /** Delete all entries in subCategroyMap and all question from asm with the sub category */
  deleteSubCat(catName: string, subCatName: string)
  {
    // delete question with subcat
    /*for(let question of this.asm.questions){
      if (question.categories[0].name == catName && question.subCategories[0].name == subCatName)
      {
        this.asm.questions.splice(this.asm.questions.indexOf(question), 1);
      }
    } */
    var i = 0;
    while (i < this.asm.questions.length)
    {
      if (this.asm.questions[i].categories[0].name == catName && this.asm.questions[i].subCategories[0].name == subCatName)
      {
        this.asm.questions.splice(this.asm.questions.indexOf(this.asm.questions[i]), 1);
      }
      else 
      {
        i++;
      }
    }

    // delete subcat in subcat map
    this.subCategoryMap.forEach((value: Categorie[], key: string) => {
      if (key == catName)
      {
        for (let subCat of value)
        {
          if (subCat.name == subCatName)
          {
            value.splice(value.indexOf(subCat), 1);
          }
        }
      }
    });
  }

  /**********************************************************************************************/

  /** delete a question with index questionIndex */
  deleteQuestion(questionIndex: number)
  {
    this.asm.questions.splice(questionIndex, 1);
  }

  /**********************************************************************************************/

  /** delete a question with index questionIndex */
  deleteAnswer(questionIndex: number, answerIndex: number)
  {
    this.asm.questions[questionIndex].answers.splice(answerIndex, 1);
  }

  /**********************************************************************************************/

  /** delete a question with index questionIndex */
  deleteRelAnswer(questionIndex: number, relAnserIndex: number)
  {
    this.asm.questions[questionIndex].relevancies[0].answers.splice(relAnserIndex, 1);
  }

  /**********************************************************************************************/

  introTextChange(eventStr: string)
  {
    if(eventStr == undefined || eventStr == "")
    {
      return;
    }

    for (let text of this.asm.introduction.text)
    {
      if (text.lang == this.translator.currentLang)
      {
        text.content = eventStr;
      }
    }
  }

  /**********************************************************************************************/

  questionTextChange(eventStr: string, question: Question)
  {
    if(eventStr == undefined || eventStr == "")
    {
      return;
    }

    for (let text of question.text)
    {
      if (text.lang == this.translator.currentLang)
      {
        text.content = eventStr;
      }
    }
  }

  /**********************************************************************************************/

  answerTextChange(eventStr: string, answer: Answer)
  {
    if(eventStr == undefined || eventStr == "")
    {
      return;
    }

    for (let text of answer.text)
    {
      if (text.lang == this.translator.currentLang)
      {
        text.content = eventStr;
      }
    }
  }

  /**********************************************************************************************/

  relevancyAnswerTextChange(eventStr: string, relevancyAnswer: RelevancyAnswer)
  {
    if(eventStr == undefined || eventStr == "")
    {
      return;
    }

    for (let text of relevancyAnswer.text)
    {
      if (text.lang == this.translator.currentLang)
      {
        text.content = eventStr;
      }
    }
  }

  /**********************************************************************************************/

  recommTextChange(eventStr: string, recomm: Recommendation)
  {
    if(eventStr == undefined || eventStr == "")
    {
      return;
    }

    for (let text of recomm.text)
    {
      if (text.lang == this.translator.currentLang)
      {
        text.content = eventStr;
      }
    }
  }

  /**********************************************************************************************/

  /** Is triggered on text change of category name - change category name and text in categoryArray, subCategoryMap and all questions */
  catTextChange(event: string, catIndex: number) : void 
  {
    if(event == undefined || event == "")
    {
      return;
    }

    // change name and content of cat in questions
    for (let question of this.asm.questions)
    {
      if (question.categories[0].name == this.categoryArray[catIndex].name)
      {
        question.categories[0].name = event;
        for (let text of question.categories[0].text)
        {
          if (text.lang == this.translator.currentLang)
          {
            text.content = event;
          }
        }
      }
    }

    // change name and content of cat in subCatMap
    this.subCategoryMap.forEach((value: Categorie[], key: string) => {
      if (key == this.categoryArray[catIndex].name)
      {
        this.subCategoryMap.set(event, value);
        this.subCategoryMap.delete(this.categoryArray[catIndex].name);
      }
    });

    // change name and content of cat array
    this.categoryArray[catIndex].name = event;
    for (let text of this.categoryArray[catIndex].text)
    {
      if (text.lang == this.translator.currentLang)
      {
        text.content = event;
      }
    }    
  }

  /**********************************************************************************************/

  /** Is triggered on text change of sub category name - change sub category name and text in subCategoryMap and all questions */
  subCatTextChange(event: string, catIndex: number, subCatIndex: number) : void 
  {
    if(event == undefined || event == "")
    {
      return;
    }

    // change name and content of subCat in questions
    for (let question of this.asm.questions)
    {
      if (question.subCategories[0].name == this.subCategoryMap.get(this.categoryArray[catIndex].name)[subCatIndex].name)
      {
        question.subCategories[0].name = event;
        for (let text of question.subCategories[0].text)
        {
          if (text.lang == this.translator.currentLang)
          {
            text.content = event;
          }
        }  
      }
    }

    // change name and content of subCat in subCatMap
    this.subCategoryMap.forEach((value: Categorie[], key: string) => {
      if (key == this.categoryArray[catIndex].name)
      {
        for (let subCat of value)
        {
          if (subCat.name == this.subCategoryMap.get(key)[subCatIndex].name)
          {
            this.subCategoryMap.get(key)[subCatIndex].name = event;
            for (let text of this.subCategoryMap.get(key)[subCatIndex].text)
            {
              if (text.lang == this.translator.currentLang)
              {
                text.content = event;
              }
            }  
          }
        }
      }
    }); 
  }
  
  /**********************************************************************************************/

  /** Fill recommendation relations for every question and save assessment to server */
  Save()
  {
    // add title
    this.asm.projects = [];
    this.asm.projects.push({_id:"", name: this.title});
    
    // add standard type
    if (this.isStandard == true)
    {
      this.asm.assessmentType = ([{"name": "standard", "text": [ {"lang": "de", "content":"Standard"}, {"lang": "en", "content":"standard"} ] }]);
    }
    else 
    {
      this.asm.assessmentType = ([{"name": "single", "text": [ {"lang": "de", "content":"Single"}, {"lang": "en", "content":"single"} ] }]);
    }

    this.isLoading = true;
    this.startTimer();
    document.body.scrollTop = 0;

    this.assessmentService.postAssessment(this.asm).subscribe(data => {
      this.isLoading = false;
      if ( data['message'] == undefined || data['message'] == null || data['message'] != 'OK')
      {
        this.popupText = "Something went wrong - Assessment Not Saved";
        this.showPopup = true;
      }
      else 
      {
        this.popupText = "Assessment Saved";
        this.showPopup = true;
      }
    },
    // redirect to login page, if user is not logged in yet
    error => {
      if(error.status == 413)
      {
        // copy questions and whipe content of questions in asm
        var questions = deepCopy<any>(this.asm.questions);
        for (var i = 0; i < this.asm.questions.length; i++)
        {
          this.asm.questions[i].answers = [];
          this.asm.questions[i].recommendations = [];
          this.asm.questions[i].relevancies = [];
        }

        this.assessmentService.postAssessment(this.asm).subscribe(data => {
          this.isLoading = false;
          if (data['message'] != 'OK')
          {
            this.popupText = "Something went wrong - Assessment Not Saved";
            this.showPopup = true;
          }
          else 
          {
            this.saveQuestionsRecursive(0, this.asm.questions.length, questions);
          }
        });
      }
    });
  }

  /***************************************************************************/

  /**
   * save changes to the question of the assessment
   */
  saveQuestionsRecursive(index: number, maxIndex: number, questions: any) : any
  {
    // max index == questions.length
    if (index == maxIndex)
    {
      this.popupText = "Assessment Saved";
      this.showPopup = true;
      return;
    }

    this.assessmentService.updateQuestion(questions[index], this.asm._id, questions[index]._id).subscribe(data => {
      if (data['message'] != 'OK')
      {
        this.popupText = "Changes could not be saved";
        this.showPopup = true;
        return false;
      }
      this.saveQuestionsRecursive(index+1, maxIndex, questions);
      return true;
    });
  }

  /***************************************************************************/
   
  /** set searchTerm to project which was selected in the dropdown menu */
  changeSelection(str: string) : void {

    this.asm.projects = [];
    this.asm.contact = [];
    this.asm.questions = [];
    this.asm.assessmentTopic = [];
    this.asm.assessmentType = [];
    this.asm.searchTags = [];
    this.asm.creatorId = "";
    this.asm.creationDate = new Date();
    this.asm.evaluationDate = new Date(0);
    this.asm.isEvaluated = false;
    this.asm.evaluatedRecommendations = [];
    this.asm.evaluationCriterias = [];
    this.asm.evaluationTypes = [];
    this.asm.introduction = {name: "introduction", text: [ {lang: "de", content:"-"}, {lang: "en", content:"-"} ] };
    this.asm.editRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });
    this.asm.viewRights.push({name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] });

    this.categoryArray = [];
    this.subCategoryMap = new Map<string, Categorie[]>();

    this.isLoading = true;
    this.startTimer();
    this.storageService.getAssessment(str);
  }

  /***************************************************************************/
   
  /** set searchTerm to project which was selected in the dropdown menu */
  loadAssessment() : void {
    this.asm = this.storageService.asm;

    // reset user rights
    this.asm.editRights = [{name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] }];
    this.asm.viewRights = [{name: this.storageService.currentUser, "text": [ {lang: "de", content:""}, {lang: "en", content:""} ] }];

    // check standard type 
    // TODO ändern in ...contains(stadard)
    if (this.asm.assessmentType[0] != undefined && this.asm.assessmentType[0].name == "standard")
    {
      this.isStandard = true;
    }
    else 
    {
      this.isStandard = false;
    }

    console.log(this.asm);

    this.changeAssessmentIds();

    // fill category arrays with distinct entries
    for (let question of this.asm.questions)
    {
      var match = false;
      for(let cat of this.categoryArray)
      {
        if (cat.name == question.categories[0].name)
        {
          match = true;
        }
      }

      if (match == false)
      {
        this.categoryArray.push(question.categories[0]);
      }
      match = false;
      
      // set subcat map if it is not set yet for the given category
      if (this.subCategoryMap.get(question.categories[0].name) == null)
      {
        this.subCategoryMap.set(question.categories[0].name, []);
      }

      // look through subcat map, if subcat is already in it for the given category
      // and add it if not
      for(let subCat of this.subCategoryMap.get(question.categories[0].name))
      {
        if (subCat.name == question.subCategories[0].name)
        {
          match = true;
        }
      }

      if (match == false)
      {
        this.subCategoryMap.get(question.categories[0].name).push(question.subCategories[0]);
      }
    }

    // reset loading screen
    this.isLoading = false;

    /*// fill category arrays with distinct entries
    for (let question of this.asm.questions)
    {
      if (!this.categoryArray.includes(question.categories[0]))
      {
        this.categoryArray.push(question.categories[0]);
      }
    }*/
  }

  /***************************************************************************/
   
  /** change Ids of Assessment questions, answers, relevancy answers, recommendations and recommendation-relations */
  changeAssessmentIds() : void {
    var tempMap: Map<string, string> = new Map<string, string>();
    
    // store all of the old IDs which must be replaced
    tempMap.set(this.asm._id, "");
    for (let question of this.asm.questions)
    {
      tempMap.set(question._id, "");
      tempMap.set(question.relevancies[0]._id, "");

      for (let answer of question.answers)
      {
        tempMap.set(answer._id, "");
      }

      for (let relAnswer of question.relevancies[0].answers)
      {
        tempMap.set(relAnswer._id, "");
      }

      for (let recomm of question.recommendations)
      {
        tempMap.set(recomm._id, "");
      }
    }

    // count how many Ids are needed
    var count = tempMap.size;

    console.log("count: " + count);

    // request new IDs from the server
    this.idHandlerService.getUniqueIdArray(count).subscribe( data => {
      if (data != null && data['idArray'].length != 0)
      {
        var idArray = data['idArray'];
        console.log(idArray);

        var tempCounter = 0;
        // create map for all old Ids corresponding to a new ID
        tempMap.forEach((value, key) => 
        {
          tempMap.set(key, idArray[tempCounter]);
          tempCounter++;
        });
        /*for (var i = 0; i < count; i++)
        {
          tempMap[i].set(idArray[i]['id']);
        }*/
        console.log(tempMap);

        // replace assessment Id
        this.asm._id = tempMap.get(this.asm._id);

        // replace all question Ids - dont forget source question for recommendations
        for (let question of this.asm.questions)
        {
          question._id = tempMap.get(question._id);
          question.relevancies[0]._id = tempMap.get(question.relevancies[0]._id);

          for (let answer of question.answers)
          {
            answer._id = tempMap.get(answer._id);
          }

          for (let relAnswer of question.relevancies[0].answers)
          {
            relAnswer._id = tempMap.get(relAnswer._id);
          }

          for (let recomm of question.recommendations)
          {
            recomm._id = tempMap.get(recomm._id);
            recomm.sourceQuestion = question._id;
          }

          question.evaluationLogic.logic.forEach(entry =>
          {
            entry['recommendation'] = tempMap.get(entry['recommendation']);

            entry['relation'].forEach(subEntry =>
            {
              subEntry['answer'] =tempMap.get(subEntry['answer']);
              subEntry['relevancy'] =tempMap.get(subEntry['relevancy']);
            });
          });
        }
      }

      console.log(this.asm);
    });
  }

  /**********************************************************************************************/

  standardCheckboxChangeEvent(event: Event)
  {
    this.isStandard = !this.isStandard;
    console.log(this.isStandard);
  }

  /**********************************************************************************************/

  /*@HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);

    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.increment();
    }
  }*/

  /***************************************************************************/

  /** 
   * Fill progress bar every 0.02 Seconds 
   * if it is full, navigate to result page 
   */
  startTimer() {
    this.progressInterval = setInterval(() => {
    
      this.progressCounter = this.progressCounter + 1;

      if (this.progressCounter >= 100)
      {
        clearInterval(this.progressInterval);
        //this.isLoading = false;
        return;
      }
    },20)
  }

  /***************************************************************************/

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
  }

}
