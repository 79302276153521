import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AssessmentService } from './api/assessment.service';
import { IdHandlerService } from './api/idHandler.service';
import { LoginService } from './api/login.service';
import { ProjectsService } from './api/projects.service';
import { RecommendationService } from './api/recommendation.service';
import { RelevanciesService } from './api/relevancies.service';
import { UserService } from './api/user.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AssessmentService,
    IdHandlerService,
    LoginService,
    ProjectsService,
    RecommendationService,
    RelevanciesService,
    UserService,
    UsersService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
