import { InjectionToken } from '@angular/core';

// import translations
import { LANG_DE_TRANS } from './lang-de';
import { LANG_EN_TRANS } from './lang-en';

/** translation token */
export const TRANSLATIONS = new InjectionToken('translations');

/** all translations */
export const dictionary = {
    'de': LANG_DE_TRANS,
    'en': LANG_EN_TRANS,
};

/** providers */
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];