import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Assessment, AssessmentService, Project, ProjectsService } from 'src/swagger-typescript';
import { FilterTranslationPipe } from 'src/translation/filter-translation.pipe';
import { TranslationService } from 'src/translation/translation.service';
import { StorageService } from '../storage.service';


export class EvaluationObject
{
  public categorie: string = "";
  public relevancyAnswerScores: number[] = [];
  public answerScores: number[] = [];
  public recommAnswerScores: number[] = [];
  public recommRelevancyScores: number[] = [];
  
  constructor(cat: string) {
    this.categorie = cat;
  }
}

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css']
})
export class CompareComponent implements OnInit {


  // TODO just temporary
  public asmHau: Assessment = null;
  public asmHornak1: Assessment = null;
  public asmHornak2: Assessment = null;
  public asmHristov: Assessment = null;
  public asmErkens: Assessment = null;
  public asmMichal: Assessment = null;
  public asmWalitschke: Assessment = null;

  /** stores all assessment projects in an array */
  private projectList : Project[] = [];

  /** stores all assessment projects in an array */
  private userRightsList : Project[] = [];
  
  /** Subscription for Errors from the DB-conenction */
  private errorSubscription: Subscription;

  /** indicate if page already loaded */
  private initialPageLoad: boolean = true;

  /** indicate which assessments are selected */
  private asmSelected: boolean[] = [];

  /** indicate which assessments version are selected */
  private versionSelected: boolean[] = [];

  /** used to display progress bar */
  private progressHidden = true;

  /** used to fill progressbar continiously */
  private progressCounter: number = 0;
  
  /** used to set cyclic interval for progressbar */
  public progressInterval;

  /** used to store all distinct question categories */
  private questionEvalCategories : Array<Object> = []; 

  /** used to show/hide navigation bar */
  private showStickyNav: boolean = false;




  /** used to store all infos needed for generating fancy graphs */
  private evaluationObjects: EvaluationObject[] = [];










  
  constructor(private storageService: StorageService,
              private projectsService: ProjectsService,
              private router: Router,
              private translator: TranslationService,
              private assessmentService: AssessmentService,
              private filterPipe: FilterTranslationPipe,) { }

  ngOnInit() {

    // TODO rausnhemen
    this.translator.use("en");





    // add scroll event to show sticky nav bar when scrolling down
    window.addEventListener('scroll', this.scrollEvent, true);

    // navigate to login page, if server responds with status 401 to assessment request
    this.errorSubscription = this.storageService.errorItem$
    .subscribe((error: any) => 
    {

      if (this.initialPageLoad == true)
      {
        this.getAllProjects();
        this.initialPageLoad = false;
        return;
      }

      if (error == null)
      {
        return;
      }
      else if(error.status == 401)
      {
        this.progressHidden = true;
        this.router.navigate(['/login', 'login']);
      }
      this.progressHidden = true;
    });



    // TODO - just temporary
    // load assessment data
    /*this.assessmentService.getAssessmentById("").subscribe( data => {
      if (data == null || data == undefined) 
      {
        alert("No assessment found for the chosen project id");
        return false;
      }
      this.asmHau = data;
      console.log("getAssessmentById asmHau FOUND");
    },
    // broadcast error if user is not logged in
    error => { console.log("getAssessmentById ERROR"); });*/
    // --------------------------------------------------------------------
    this.assessmentService.getAssessmentById("603752b609790512304a053b").subscribe( data => {
    //this.assessmentService.getAssessmentById("5f1a9bf92712453bdd366e51").subscribe( data => {
      if (data == null || data == undefined) 
      {
        alert("No assessment found for the chosen project id");
        return false;
      }
      this.asmHornak1 = data;


      // fill question category array
      var helperArray: any[] = [];  
      for (let questionCats of this.asmHornak1.questions)
      {
        var text = this.filterPipe.transform(questionCats.categories[0].text, this.translator.currentLang);
        helperArray.push(text);
      }

      helperArray = helperArray.filter(this.uniqueFilter); 
      this.evaluationObjects = [];
      for (let cats of helperArray)
      {
        this.evaluationObjects.push(new EvaluationObject(cats));
      }

      // fill category array
      for (let evalCats of this.asmHornak1.questions)
      {
        this.questionEvalCategories.push(evalCats.categories[0]);
      }
      console.log("getAssessmentById asmHornak1 FOUND");

          // --------------------------------------------------------------------
          this.assessmentService.getAssessmentById("603752b609790512304a053b").subscribe( data => {
          //this.assessmentService.getAssessmentById("5f1a9c10271245082e369b5b").subscribe( data => {
            if (data == null || data == undefined) 
            {
              alert("No assessment found for the chosen project id");
              return false;
            }
            this.asmHornak2 = data;
            console.log("getAssessmentById asmHornak2 FOUND");

                // --------------------------------------------------------------------
                this.assessmentService.getAssessmentById("602ea0a8586f7f349df6c37b").subscribe( data => {
                  if (data == null || data == undefined) 
                  {
                    alert("No assessment found for the chosen project id");
                    return false;
                  }
                  this.asmHristov = data;
                  console.log("getAssessmentById asmHristov FOUND");

                      // --------------------------------------------------------------------
                      this.assessmentService.getAssessmentById("602ea0e1586f7f5877f71545").subscribe( data => {
                        if (data == null || data == undefined) 
                        {
                          alert("No assessment found for the chosen project id");
                          return false;
                        }
                        this.asmErkens = data;
                        console.log("getAssessmentById asmErkens FOUND");

                            // --------------------------------------------------------------------
                            this.assessmentService.getAssessmentById("602ea133586f7f386bf78ff4").subscribe( data => {
                              if (data == null || data == undefined) 
                              {
                                alert("No assessment found for the chosen project id");
                                return false;
                              }
                              this.asmMichal = data;
                              console.log("getAssessmentById asmMichal FOUND");

                                  // --------------------------------------------------------------------
                                  this.assessmentService.getAssessmentById("602ea0fe586f7f35f4f73e2a").subscribe( data => {
                                    if (data == null || data == undefined) 
                                    {
                                      alert("No assessment found for the chosen project id");
                                      return false;
                                    }
                                    this.asmWalitschke = data;
                                    console.log("getAssessmentById asmWalitschke FOUND");
                                  },
                                  // broadcast error if user is not logged in
                                  error => { console.log("getAssessmentById ERROR"); });
                            },
                            // broadcast error if user is not logged in
                            error => { console.log("getAssessmentById ERROR"); });
                      },
                      // broadcast error if user is not logged in
                      error => { console.log("getAssessmentById ERROR"); });
                },
                // broadcast error if user is not logged in
                error => { console.log("getAssessmentById ERROR"); });
          },
          // broadcast error if user is not logged in
          error => { console.log("getAssessmentById ERROR"); });
    },
    // broadcast error if user is not logged in
    error => { console.log("getAssessmentById ERROR"); });
    

  }

  /***************************************************************************/

  /**
   * Lifecycle hook - delete all subscriptions to avoid memory leak
   */
  ngOnDestroy() 
  {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  /***************************************************************************/

  getAllProjects() 
  {
    this.projectsService.getAllProjects().subscribe( data => {
      for (let entry of data)
      {
        this.projectList.push(entry);
      }
    },
    // redirect to login page, if user is not logged in yet
    error => {
      if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });
  }

  /***************************************************************************/
    
  /**
   * Used to return a list of the input category array in the current language
   * @param categories Array of Category Objects
   */
  getCurrentLangListForNavigation(categories: Array<Object>){
    if (categories == undefined)
    {
      return [];
    }
    
    var helperArray = [];
    
    for (let cat of categories)
    {
      var matchFound = false;
      for (let helperEntry of helperArray)
      {
        if (helperEntry['name'] == cat['name'])
        {
          matchFound = true;
          break;
        }
      }
      if (matchFound == false)
      {
        helperArray.push(cat);
      }
      matchFound = false;

      //helperArray.push(this.filterPipe.transform(cat['text'], this.translator.currentLang));
    }

    //helperArray = helperArray.filter(this.uniqueFilter);
    return helperArray;
  }

  /***************************************************************************/

  /** Event to show navbar when a specific scroll distance is reached */
  scrollEvent = (event: any): void => {
    const scrollNumber = event.srcElement.scrollTop;
    if (this.showStickyNav != true && event.srcElement.classList.contains('specificBody') == true && scrollNumber >= 100 )
    {
      this.showStickyNav = true;
    }
    else if (this.showStickyNav != false && event.srcElement.classList.contains('specificBody') == true && scrollNumber < 100 )
    {
      this.showStickyNav = false;
    }
  }

  /***************************************************************************/
    
  /** navigatee back to the top of the page */
  linkToTop() {
    document.getElementById('top').scrollIntoView();
  }

  /***************************************************************************/

  /** 
   * Fill progress bar every 0.02 Seconds 
   * if it is full, navigate to result page 
   */
  startTimer() {
    this.progressInterval = setInterval(() => {
    
      this.progressCounter = this.progressCounter + 1;

      if (this.progressCounter >= 100)
      {
        clearInterval(this.progressInterval);
        //this.isLoading = false;
        return;
      }
    },20)
  }

  /***************************************************************************/
  
  selectAssessment(index: number)
  {
    this.asmSelected[index] = !this.asmSelected[index]
  }












  /***************************************************************************/
      
  /***************************************************************************/
    
  /**
   * Used to filter out multible entries in an array 
   * only unique ones remain in the array
   * @param value current value of the array
   * @param index current index
   * @param self input array (?)
   */
  uniqueFilter(value, index, self) { 
    return self.indexOf(value) === index;
  }

  /***************************************************************************/
    
  /**
   * Used to filter out all recommendations that are not part of the input category
   * @param cat Category name
   */
  filterQuestionOfCategoryHornak1(cat){
    return this.asmHornak1.questions
      .filter(data => this.filterPipe
        .transform(data.categories[0].text, this.translator.currentLang) == cat);
  }

  /***************************************************************************/
    
  /**
   * Used to filter out all recommendations that are not part of the input category
   * @param cat Category name
   */
  filterQuestionOfCategoryHornak2(cat){
    return this.asmHornak2.questions
      .filter(data => this.filterPipe
        .transform(data.categories[0].text, this.translator.currentLang) == cat);
  }

  /***************************************************************************/
    
  /**
   * Used to filter out all recommendations that are not part of the input category
   * @param cat Category name
   */
  filterQuestionOfCategoryHristov(cat){
    return this.asmHristov.questions
      .filter(data => this.filterPipe
        .transform(data.categories[0].text, this.translator.currentLang) == cat);
  }

  /***************************************************************************/
    
  /**
   * Used to filter out all recommendations that are not part of the input category
   * @param cat Category name
   */
  filterQuestionOfCategoryHau(cat){
    return this.asmHau.questions
      .filter(data => this.filterPipe
        .transform(data.categories[0].text, this.translator.currentLang) == cat);
  }

  /***************************************************************************/
    
  /**
   * Used to filter out all recommendations that are not part of the input category
   * @param cat Category name
   */
  filterQuestionOfCategoryErkens(cat){
    return this.asmErkens.questions
      .filter(data => this.filterPipe
        .transform(data.categories[0].text, this.translator.currentLang) == cat);
  }

  /***************************************************************************/
    
  /**
   * Used to filter out all recommendations that are not part of the input category
   * @param cat Category name
   */
  filterQuestionOfCategoryMichal(cat){
    return this.asmMichal.questions
      .filter(data => this.filterPipe
        .transform(data.categories[0].text, this.translator.currentLang) == cat);
  }

  /***************************************************************************/
    
  /**
   * Used to filter out all recommendations that are not part of the input category
   * @param cat Category name
   */
  filterQuestionOfCategoryWalitschke(cat){
    return this.asmWalitschke.questions
      .filter(data => this.filterPipe
        .transform(data.categories[0].text, this.translator.currentLang) == cat);
  }



  /***************************************************************************/
    
  buildSumOfAnswers(catText:any, i:number, k: number, q: number)
  {
    var sum = 0;

    //this.filterQuestionOfCategoryHornak1(category.text | filterTranslation: translator.currentLang)[k].answers[q].isSelected + filterQuestionOfCategoryHornak2(category.text | filterTranslation: translator.currentLang)[k].answers[q].isSelected + filterQuestionOfCategoryHau(category.text | filterTranslation: translator.currentLang)[k].answers[q].isSelected + filterQuestionOfCategoryHristov(category.text | filterTranslation: translator.currentLang)[k].answers[q].isSelected + filterQuestionOfCategoryErkens(category.text | filterTranslation: translator.currentLang)[k].answers[q].isSelected + filterQuestionOfCategoryWalitschke(category.text | filterTranslation: translator.currentLang)[k].answers[q].isSelected + filterQuestionOfCategoryMichal(category.text | filterTranslation: translator.currentLang)[k].answers[q].isSelected
    if (this.asmHornak1 != null) 
      sum = sum + (+this.filterQuestionOfCategoryHornak1(this.filterPipe.transform(catText, this.translator.currentLang))[k].answers[q].isSelected);
    if (this.asmHornak2 != null) 
      sum = sum + (+this.filterQuestionOfCategoryHornak2(this.filterPipe.transform(catText, this.translator.currentLang))[k].answers[q].isSelected);
    if (this.asmHau != null) 
      sum = sum + (+this.filterQuestionOfCategoryHau(this.filterPipe.transform(catText, this.translator.currentLang))[k].answers[q].isSelected);
    if (this.asmHristov != null) 
      sum = sum + (+this.filterQuestionOfCategoryHristov(this.filterPipe.transform(catText, this.translator.currentLang))[k].answers[q].isSelected);
    if (this.asmErkens != null) 
      sum = sum + (+this.filterQuestionOfCategoryErkens(this.filterPipe.transform(catText, this.translator.currentLang))[k].answers[q].isSelected);
    if (this.asmMichal != null) 
      sum = sum + (+this.filterQuestionOfCategoryMichal(this.filterPipe.transform(catText, this.translator.currentLang))[k].answers[q].isSelected);
    if (this.asmWalitschke != null) 
      sum = sum + (+this.filterQuestionOfCategoryWalitschke(this.filterPipe.transform(catText, this.translator.currentLang))[k].answers[q].isSelected);

    return sum;
  }

  /***************************************************************************/
    
  buildSumOfRelAnswers(catText:any, i:number, k: number, q: number)
  {
    var sum = 0;

    //this.filterQuestionOfCategoryHornak1(category.text | filterTranslation: translator.currentLang)[k].relevancies[0].answers[q].isSelected + filterQuestionOfCategoryHornak2(category.text | filterTranslation: translator.currentLang)[k].relevancies[0].answers[q].isSelected + filterQuestionOfCategoryHau(category.text | filterTranslation: translator.currentLang)[k].relevancies[0].answers[q].isSelected + filterQuestionOfCategoryHristov(category.text | filterTranslation: translator.currentLang)[k].relevancies[0].answers[q].isSelected + filterQuestionOfCategoryErkens(category.text | filterTranslation: translator.currentLang)[k].relevancies[0].answers[q].isSelected + filterQuestionOfCategoryWalitschke(category.text | filterTranslation: translator.currentLang)[k].relevancies[0].answers[q].isSelected + filterQuestionOfCategoryMichal(category.text | filterTranslation: translator.currentLang)[k].relevancies[0].answers[q].isSelected
    if (this.asmHornak1 != null) 
      sum = sum + (+this.filterQuestionOfCategoryHornak1(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[q].isSelected);
    if (this.asmHornak2 != null) 
      sum = sum + (+this.filterQuestionOfCategoryHornak2(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[q].isSelected);
    if (this.asmHau != null) 
      sum = sum + (+this.filterQuestionOfCategoryHau(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[q].isSelected);
    if (this.asmHristov != null) 
      sum = sum + (+this.filterQuestionOfCategoryHristov(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[q].isSelected);
    if (this.asmErkens != null) 
      sum = sum + (+this.filterQuestionOfCategoryErkens(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[q].isSelected);
    if (this.asmMichal != null) 
      sum = sum + (+this.filterQuestionOfCategoryMichal(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[q].isSelected);
    if (this.asmWalitschke != null) 
      sum = sum + (+this.filterQuestionOfCategoryWalitschke(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[q].isSelected);

    return sum;
  }

  /***************************************************************************/
    
  buildSumOfComments(catText:any, k: number, q: number)
  {
    var sum = "";

    if (this.asmHornak1 != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryHornak1(this.filterPipe.transform(catText, this.translator.currentLang))[k].comment[0].content) + "\n";
    if (this.asmHornak2 != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryHornak2(this.filterPipe.transform(catText, this.translator.currentLang))[k].comment[0].content) + "\n";
    if (this.asmHau != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryHau(this.filterPipe.transform(catText, this.translator.currentLang))[k].comment[0].content) + "\n";
    if (this.asmHristov != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryHristov(this.filterPipe.transform(catText, this.translator.currentLang))[k].comment[0].content) + "\n";
    if (this.asmErkens != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryErkens(this.filterPipe.transform(catText, this.translator.currentLang))[k].comment[0].content) + "\n";
    if (this.asmMichal != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryMichal(this.filterPipe.transform(catText, this.translator.currentLang))[k].comment[0].content) + "\n";
    if (this.asmWalitschke != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryWalitschke(this.filterPipe.transform(catText, this.translator.currentLang))[k].comment[0].content) + "\n";

    return sum; 
  }

  /***************************************************************************/
    
  buildSumOfRelComments(catText:any, k: number, q: number)
  {
    var sum = "";

    if (this.asmHornak1 != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryHornak1(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[0].comment[0].content) + "\n";
    if (this.asmHornak2 != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryHornak2(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[0].comment[0].content) + "\n";
    if (this.asmHau != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryHau(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[0].comment[0].content) + "\n";
    if (this.asmHristov != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryHristov(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[0].comment[0].content) + "\n";
    if (this.asmErkens != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryErkens(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[0].comment[0].content) + "\n";
    if (this.asmMichal != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryMichal(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[0].comment[0].content) + "\n";
    if (this.asmWalitschke != null) 
      sum = sum + "# " + (this.filterQuestionOfCategoryWalitschke(this.filterPipe.transform(catText, this.translator.currentLang))[k].relevancies[0].answers[0].comment[0].content) + "\n";

    return sum; 
  }
}
