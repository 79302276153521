import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-assessment',
  templateUrl: './overview-assessment.component.html',
  styleUrls: ['./overview-assessment.component.css']
})
export class OverviewAssessmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
