import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/swagger-typescript/api/users.service';
import { UserService } from 'src/swagger-typescript/api/user.service';
import { User } from 'src/swagger-typescript/model/User';
import { LoginService } from 'src/swagger-typescript/api/login.service';
import { StorageService } from 'src/app/storage.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /** store username */
  private username: string = '';
  
  /** store email adress */
  private mail: string = '';
  
  /** store password of the upper input field */
  private password1: string = '';
  
  /** store password of the lower input field */
  private password2: string = '';

  private pageType: string = 'register'

  private loginText: string = 'Register'

  private projectId: string = '';

  /** used to indicate if popup is shown */
  private showPopup: boolean = false;

  /** text which is shown on popup */
  private popupText: string = "";

  public forgotPassword: boolean = false;

  /** constructor */
  constructor(private usersService: UsersService,
              private loginService: LoginService,
              private router: Router,
              private storageService: StorageService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute
              ) { }

  /******************************************************************************/

  ngOnInit() {
    this.pageType = this.activatedRoute.snapshot.paramMap.get("type");
    this.projectId = this.activatedRoute.snapshot.paramMap.get("id");

    if (this.pageType == "login")
    {
      this.loginText = "Login";
    }
  }

  /******************************************************************************/

  navigateToLogin() 
  {
    this.pageType = "login";
    this.loginText = "Login";
    this.router.navigate(['./login', 'login']);
  }

  /******************************************************************************/

  navigateToRegister() 
  {
    this.pageType = "register";
    this.loginText = "Register";
    this.router.navigate(['./login', 'register']);
  }

  /******************************************************************************/

  navigateToForgotPassword() 
  {
    this.pageType = "login";
    this.loginText = "Login";
    this.forgotPassword = true;
  }

  /******************************************************************************/

  /** Send post request to register new user  */
  register() : void
  {

    // TODO wieder rausnehmen...
    this.popupText = "Registration currently not available";
    this.showPopup = true;
    return;

    // check if passwords in field one and two are matching
    if (this.password1 != this.password2)
    {
      this.popupText = "Passwords do not match";
      this.showPopup = true;
      return;
    }

    var user = new Object 
    user = {
      'name' : this.username,
      'mail' : this.mail,
      'password' : this.password1,
    }

    // post new User to server
    this.usersService.postUser(<any>user).subscribe( (data:any) => {
      if (data['message'] == "User already exists"){
        this.popupText = "User already exists";
        this.showPopup = true;
      }
      else if (data['message'] == "OK"){
        this.popupText = "New user registered";
        this.showPopup = true;
      }
      else {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    });
  }

  /******************************************************************************/

  /** send post request to login a user */
  login() : void
  {
    // check if passwords in field one and two are matching
    if (this.password1 == '' || this.username == '')
    {
      this.popupText = "Please enter credentials";
      this.showPopup = true;
      return;
    }

    var user = new Object 
    user = {
      'name' : this.username,
      'mail' : this.mail,
      'password' : this.password1,
    }

    this.loginService.login(<any>user).subscribe( (data:any) => {
      
      if (data['message'] == "Wrong username" || data['message'] == "Wrong password"){
        this.popupText = "Wrong input credentials";
        this.showPopup = true;
      }
      else if (data['message'] == "Success" && data['name'] != null && data['name'] != ""){

        this.storageService.setCurrentUser(data['name']);

        this.userService.getSessionUserType().subscribe( data => {
          if (data != null && data['message'] != "")
          {
            this.storageService.setCurrentUserType(data['message']);

            if (this.projectId != undefined && this.projectId != "")
            {
              console.log("navigate '/remoteReview' ");
              this.router.navigate(['/remoteReview', this.projectId]);
            }
            else 
            {
              this.router.navigate(['/dashboard']);
            }
          }
        });    
      }
      else {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    },
    error => {
      if(error.status == 401)
      {
        this.popupText = "Wrong input credentials";
        this.showPopup = true;
      }
    });
  }

  /******************************************************************************/

  /** send post request to logout the current user */
  logout() : void
  {
    this.loginService.logout({_id:"", name:"", password:"", mail:""}).subscribe( (data:any) => {
      
      if (data['message'] == "Logout failed"){
        this.popupText = "Logout failed";
        this.showPopup = true;
      }
      else if (data['message'] == "Success"){
        this.storageService.setCurrentUser("");
        this.router.navigate(['/login', 'login']);
      }
      else {
        this.popupText = "Something went wrong";
        this.showPopup = true;
      }
    },
    error => {
      if(error.status == 500)
      {
        this.popupText = "Logout failed";
        this.showPopup = true;
      }
    });

  }

  /***************************************************************************/

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string)
  {
    this.popupText = "";
    this.showPopup = false;
  }

  /**********************************************************************************************/

  /** "forgot password" request was submitted
   * 
   * @param {string} eventStr event string that was emitted 
   */
  forgotPasswordEvent(eventStr: string)
  {
    this.forgotPassword = false;
  }
}
