
import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '../../swagger-typescript/api/assessment.service';
import { Assessment } from '../../swagger-typescript/model/assessment';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslationService } from '../../translation/translation.service';
import { FilterTranslationPipe } from '../../translation/filter-translation.pipe'
import { Categorie } from '../../swagger-typescript/model/categorie';
import { Subscription } from 'rxjs';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.css']
})
export class IntroductionComponent implements OnInit {

  /** used to store the assessment retrieved from the DB */
  private asm: Assessment = null;

  /** used to store project name */
  private projectName: string = "";

  /** indicate which question is currently answered */
  private currentQuestion: number = 0;

  /** Used to store all category names */
  private categoryArray: Categorie[] = [];

  /** Subscription for Assessments from the DB */
  private asmSubscription: Subscription;

  /** Subscription for Errors from the DB-conenction */
  private errorSubscription: Subscription;

  /** constructor */
  constructor(private assessmentService: AssessmentService,
              private router: Router,
              private route: ActivatedRoute,
              private translator: TranslationService,
              private filterPipe: FilterTranslationPipe,
              private storageService: StorageService,
              ) { }

  /***************************************************************************/

  /**
   * Lifecycle hook - triggered on page initialization
   * Load chosen assessment and display selected question
   */
  ngOnInit() {

    // (+) before params turns the string into a number
    //this.currentQuestion = +this.route.snapshot.paramMap.get('question');
    this.projectName = this.route.snapshot.paramMap.get('project');

    // prevent empty navigation
    if (this.projectName == '')
    {
      this.router.navigate(['/dashboard']);
    }

    // load assessment data
    this.asmSubscription = this.storageService.asmItem$
    .subscribe((data: Assessment) => 
    {
      // no assessment found for that project name
      if (data == false || data == null || data == undefined) 
      {
        //alert('No assessment found for the chosen project name');
        return;
      }
      this.asm = data;
      this.categoryArray = [];

      // fill category array 
      for (let questionCats of this.asm.questions)
      {
        this.categoryArray.push(questionCats.categories[0]);
      }

      this.categoryArray = this.categoryArray.filter(this.uniqueFilter); 

      // switch language if assessment only has one language filled out
      var introCount = 0;
      var introLang = "";
      for(let text of this.asm.introduction.text)
      {
        if (text.content != "" && text.content != "-" && text.content != " ")
        {
          introCount++;
          introLang = text.lang;
        }
      }

      if (introCount == 1 && introLang != "")
      {
        this.translator.use(introLang);
      }

    });

    // navigate to login page, if server responds with status 401 to assessment request
    this.errorSubscription = this.storageService.errorItem$
    .subscribe((error: any) => 
    {
      if (error == null)
      {
        return;
      }
      else if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });
    
    /*// load assessment data
    this.assessmentService.getAssessment(this.projectName).subscribe( data => {
      if (data == null || data == undefined) 
      {
        alert('No assessment found for the chosen project name');
        return;
      }
      this.asm = data;

      // fill category array 
      for (let questionCats of this.asm.questions)
      {
        this.categoryArray.push(questionCats.categories[0]);
      }

      this.categoryArray = this.categoryArray.filter(this.uniqueFilter); 


    },
    // redirect to login page, if user is not logged in yet
    error => {
      if(error.status == 401)
      {
        this.router.navigate(['/login', 'login']);
      }
    });*/
  }

  /***************************************************************************/
   
  /**
   * Lifecycle hook - delete all subscriptions to avoid memory leak
   */
  ngOnDestroy() 
  {
    this.asmSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }

  /***************************************************************************/

  /** toggle comment pop up */
  toggleComment(event: Event, i?: number) {
    
    const hasClass = (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.contains('isVisible');
    if (hasClass)
    {
      (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.remove('isVisible');
      (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.add('isNotVisible');
      (<HTMLInputElement>event.currentTarget).children[0].innerHTML = "+";
    }
    else 
    {
      (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.remove('isNotVisible');
      (<HTMLInputElement>event.currentTarget).nextElementSibling.classList.add('isVisible');
      (<HTMLInputElement>event.currentTarget).children[0].innerHTML = "-";
    }
  }

  /***************************************************************************/

  /**
   * Navigate to chosen category of assessment
   */
  jumpToCategory(categoryName: string) {

    this.router.navigate(['/surveys/' + this.asm.projects[0].name + '/' + categoryName]);
  }

  /***************************************************************************/

  /**
   * Select ONE answer to the relevancy
   */
  selectRelevancy(index: number) {

    // select / deselect the clicked answer
    if (this.asm.questions[this.currentQuestion].relevancies[0].answers[index].isSelected == true)
    {
      this.asm.questions[this.currentQuestion].relevancies[0].answers[index].isSelected = false;
    }
    else 
    {
      this.asm.questions[this.currentQuestion].relevancies[0].answers[index].isSelected = true;
    }

    // loop through all answers and deselect all that were not clicked
    for (var k = 0; k < this.asm.questions[this.currentQuestion].relevancies[0].answers.length; k++)
    {
      if (k != index)
      {
        this.asm.questions[this.currentQuestion].relevancies[0].answers[k].isSelected = false;
      }
    }  
  }

  /***************************************************************************/

  /**
   * navigate to the next question
   */
  next() : void 
  {
    this.router.navigate(['/surveys/' + this.asm.projects[0].name ]);
  }

  /***************************************************************************/

  /**
   * navigate to the last question
   */
  back() : void 
  {
    this.router.navigate(['/dashboard']);
  }

  /***************************************************************************/
    
  /**
   * Used to filter out multible entries in an array 
   * only unique ones remain in the array
   * @param value current value of the array
   * @param index current index
   * @param self input array (?)
   */
  uniqueFilter(value: Categorie, index, self) { 
    if (index == (self.length - 1)) return true;
    else if (self[index].name == self[index + 1].name) return false;
    return true;
  }

}
